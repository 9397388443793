import { Box, Card, Stack, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import ConnectAccountMessage from './ConnectAccountMessage.tsx';
import { useAuth } from 'UseAuth.tsx';
import ExternalLink from 'components/technical/ExternalLink/ExternalLink.tsx';

const ContactSupportMessage = (): ReactElement => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        width: 'max-content',
      }}
    >
      <Card variant="outlined">
        <Stack
          alignItems="center"
          sx={{
            padding: {
              sm: '0 2rem',
            },
          }}
          spacing={1.5}
        >
          <Typography color="neutral" level="body-sm">
            <ExternalLink to="mailto:federico@genieai.tech">Contact our team</ExternalLink> to get access to this
            feature
          </Typography>
        </Stack>
      </Card>
    </Box>
  );
};

export const ChartNoAccountFallback = ({
  children,
  refetch,
  adminOnly = true,
}: {
  children: ReactNode;
  refetch: () => void;
  adminOnly?: boolean;
}): ReactElement => {
  const { isAdmin } = useAuth();

  return (
    <Stack sx={{ position: 'relative' }} spacing={1.5}>
      {children}
      {adminOnly && isAdmin ? (
        <ConnectAccountMessage
          onConnected={(): void => {
            refetch();
          }}
        />
      ) : (
        <ContactSupportMessage />
      )}
    </Stack>
  );
};
