import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/joy';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { type Dispatch, type FunctionComponent, type SetStateAction, useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import GTable from 'components/technical/GTable/GTable';
import type { GColumnDef } from 'components/technical/GTable/GTable.props';

import {
  AssetGroupsDocument,
  type IAssetGroupsQuery,
  useDeleteAssetGroupMutation,
  useUpdateAssetGroupMutation,
} from '../../../../generated/graphql';
import type { UserGroup } from './AssetGroupsDashboard';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import ChangeGroupAssetsDialog from './ChangeGroupAssetsDialog';
import AssetLabel from '../AssetLabel';
import { IconVariant } from '../cryptocurrencies/CryptocurrenciesData';
import { getAssetName } from '../AssetService';
import { sortBy } from 'lodash/fp';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator';
import { Information } from 'components/technical/icons/index.tsx';
import { getAssets } from '../Asset.types';
import { DeleteOutline } from '@mui/icons-material';

type GroupListProps = {
  clusterGroups: UserGroup[];
  assets: IAssetGroupsQuery['assets']['feature'];
  existingGroups: Set<string>;
  setSelectedCluster: Dispatch<SetStateAction<string | null>>;
  clusters: string[];
};

const columnHelper = createColumnHelper<UserGroup>();

const ClusterGroupsTable: FunctionComponent<GroupListProps> = ({
  clusterGroups,
  assets,
  setSelectedCluster,
  clusters,
}) => {
  const [deleteAssetGroup] = useDeleteAssetGroupMutation({ refetchQueries: [AssetGroupsDocument] });
  const [updateAssetGroup] = useUpdateAssetGroupMutation();

  const { showGraphqlError, showSuccessMessage } = useFeedback();
  const timezone = useUserTimezone();

  const [editRow, setEditRow] = useState<UserGroup | null>(null);
  const [deleteRow, setDeleteRow] = useState<UserGroup | null>(null);

  const { tablePaginator, page } = useTablePaginator({
    defaultPageSize: 25,
  });

  // First, sort groups with assets
  const sorted = sortBy((group) => [group.assets.length > 0 ? 0 : 1, group.groupName], clusterGroups);
  const pageRows = sorted.slice(page.offset, page.offset + page.limit);

  // Define columns with hover-based action buttons
  const columns: GColumnDef<UserGroup>[] = useMemo(
    () => [
      {
        header: 'Group name',
        meta: {
          headerStyles: { width: '200px' },
        },
        cell: (props: CellContext<UserGroup, unknown>) => {
          const tooltipContent = (
            <Stack>
              <Typography>
                Added on: {formatDate(props.row.original.createdAt, DateTimeFormat.DateTime, timezone)}
              </Typography>
              <Typography>
                Last updated: {formatDate(props.row.original.updatedAt, DateTimeFormat.DateTime, timezone)}
              </Typography>
            </Stack>
          );

          return (
            <Stack direction="row" alignItems="center" spacing={0.4}>
              <Typography level="body-xs">{props.row.original.groupName}</Typography>
              <Tooltip title={tooltipContent}>
                <Information fontSize="sm" />
              </Tooltip>
            </Stack>
          );
        },
      },
      columnHelper.display({
        header: 'Assets',
        meta: {
          align: 'center',
          headerStyles: { flex: 1 },
        },
        cell: (props: CellContext<UserGroup, unknown>) => {
          const sortedAssets = sortBy((a) => getAssetName(a), props.row.original.assets);
          return (
            <Stack
              direction="row"
              sx={{
                minWidth: 150,
                alignItems: 'stretch',
                justifyContent: 'start',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {sortedAssets.map((asset) => (
                // display: 'flex' fixes vertical alignment
                <Chip key={asset.id} sx={{ cursor: 'default' }} slotProps={{ label: { sx: { display: 'flex' } } }}>
                  <AssetLabel asset={asset} size={IconVariant.MEDIUM} />
                </Chip>
              ))}
            </Stack>
          );
        },
      }),
      columnHelper.display({
        header: 'Actions',
        meta: {
          headerStyles: { width: '150px' },
          align: 'center',
        },
        cell: (cellProps: CellContext<UserGroup, unknown>) => {
          const assetGroupRow = cellProps.row.original;

          return (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              <Tooltip title="Edit asset group">
                <IconButton
                  variant="plain"
                  color="primary"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditRow(assetGroupRow);
                  }}
                  aria-label="Edit asset group"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove asset group">
                <IconButton
                  variant="soft"
                  color="danger"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteRow(assetGroupRow);
                  }}
                  aria-label="Remove asset group"
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      }),
    ],
    [timezone]
  );

  return (
    <>
      <GTable<UserGroup>
        columns={columns}
        data={pageRows}
        paginator={tablePaginator}
        totalResults={clusterGroups.length}
      />

      {/* Edit Dialog */}
      {editRow && (
        <ChangeGroupAssetsDialog
          onClose={() => setEditRow(null)}
          assets={getAssets(
            assets.filter(
              (asset) =>
                !clusterGroups.some(
                  // Exclude assets already belonging to other groups in the same cluster
                  (group) =>
                    group.groupName !== editRow.groupName &&
                    group.assets.some((anotherGroupAsset) => anotherGroupAsset.id === asset.id)
                )
            )
          )}
          initialState={editRow}
          handleFormSubmit={async (updatedGroup, onErrorAndThrow): Promise<void> => {
            try {
              await updateAssetGroup({
                variables: {
                  input: {
                    oldGroupName: editRow.groupName,
                    newGroupName: updatedGroup.groupName,
                    clusterName: editRow.clusterName,
                    assets: updatedGroup.assets.map((asset) => asset.id),
                  },
                },
              });
              showSuccessMessage('Asset group successfully updated');
              setEditRow(null);
            } catch (e) {
              onErrorAndThrow(e);
            }
          }}
        />
      )}

      {/* Delete Confirmation Dialog */}
      {deleteRow && (
        <ConfirmationDialog
          onClose={() => setDeleteRow(null)}
          onApprove={async (): Promise<void> => {
            try {
              await deleteAssetGroup({
                variables: {
                  input: { groupName: deleteRow.groupName },
                },
              });
              if (clusterGroups.length === 1) {
                setSelectedCluster(clusters.find((cluster) => cluster !== deleteRow.clusterName) ?? null);
              }
              showSuccessMessage('Asset group successfully deleted');
              setDeleteRow(null);
            } catch (e) {
              showGraphqlError(e);
              setDeleteRow(null);
            }
          }}
        >
          {clusterGroups.length === 1
            ? `Are you sure you want to remove asset group "${deleteRow.groupName}"? Removing the last asset group will also delete its associated cluster.`
            : `Are you sure you want to remove asset group "${deleteRow.groupName}"?`}
        </ConfirmationDialog>
      )}
    </>
  );
};

export default ClusterGroupsTable;
