import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { useFinalColorScheme } from '../../../useFinalColorScheme';
import GLink from '../GLink/GLink';
import { GenieText } from '../icons';

export const SidebarTop = (): ReactElement => {
  const colorScheme = useFinalColorScheme();
  return (
    <Stack justifyContent="center" width="100%" direction="row" p={1} spacing={1.5}>
      <GLink to="/app">
        <GenieText
          sx={{
            width: '9rem',
            height: 'auto',
            aspectRatio: '4',
            fill: colorScheme === 'light' ? '#303136' : 'white', // logo colors for white and dark variants
          }}
        />
      </GLink>
    </Stack>
  );
};
