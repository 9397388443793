import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';

import type { Asset } from './Asset.types.ts';
import { type IAsset, IAssetType } from '../../../generated/graphql.tsx';
import type { RecursivePartial } from '../../type.utils.ts';

const hasNonNilPaths = (obj: object | null, paths: string[]): boolean => {
  if (obj === null) {
    return false;
  }

  return paths.every((path) => !isNil(get(path, obj)));
};

type AssetLabelValidationResult =
  | {
      isValid: false;
      missingField?: string;
    }
  | {
      isValid: true;
    };

const internalIsAssetLabelInput = (
  asset:
    | RecursivePartial<Asset>
    | RecursivePartial<IAsset>
    | RecursivePartial<NonNullable<IAsset['derivativeDetails']>['baseAsset']>
): AssetLabelValidationResult => {
  if (isNil(asset) || typeof asset !== 'object') {
    return { isValid: false };
  }

  const objAsset = asset;
  const type = get('type', objAsset);
  if (isNil(type)) {
    return { isValid: false, missingField: 'type' };
  }

  if (isNil(get('symbol', objAsset))) {
    return { isValid: false, missingField: 'symbol' };
  }

  if (type === IAssetType.Exchange) {
    return hasNonNilPaths(asset, ['exchangeDetails'])
      ? { isValid: true }
      : { isValid: false, missingField: 'exchangeDetails' };
  }

  if (isNil(get('id', objAsset))) {
    return { isValid: false, missingField: 'id' };
  }

  if (type === IAssetType.Derivative) {
    return isNil(get('derivativeDetails.exchange', asset))
      ? { isValid: false, missingField: 'derivativeDetails.exchange' }
      : { isValid: true };
  }
  if (type === IAssetType.Private || type === IAssetType.Public || type === IAssetType.Unvested) {
    return { isValid: true };
  }

  return { isValid: false };
};

// don't include priceAsset because it's a recursive infinite type
export type NotVerifiedAsset = Omit<
  RecursivePartial<Omit<IAsset, 'priceAsset'>> &
    RecursivePartial<NonNullable<IAsset['derivativeDetails']>['baseAsset']>,
  '__typename'
>;

const assetsVerificationCache = new WeakMap<NotVerifiedAsset, boolean>();

// @deprecated
export const isAssetLabelInput = (asset: NotVerifiedAsset | undefined | null): asset is AssetLabelInput => {
  if (isNil(asset)) {
    return false;
  }

  // cache helps to avoid polluting console with multiple errors for the same asset
  if (assetsVerificationCache.has(asset)) {
    return assetsVerificationCache.get(asset) as boolean;
  }
  const validationResult = internalIsAssetLabelInput(asset);
  if (!validationResult.isValid) {
    console.trace('Asset', asset, 'missing fields', validationResult.missingField ?? '');
  }
  assetsVerificationCache.set(asset, validationResult.isValid);

  return validationResult.isValid;
};

// @deprecated
export type DerivativeAssetLabelInput = Pick<IAsset, 'label' | 'symbol' | 'id'> & {
  type: IAssetType.Derivative;
  derivativeDetails: {
    exchange: string;
  };
};

// @deprecated
export type PrivatePublicUnvestedAssetLabelInput = Pick<IAsset, 'label' | 'symbol' | 'id'> &
  (
    | {
        type: IAssetType.Private | IAssetType.Unvested;
        name?: string | null;
      }
    | {
        type: IAssetType.Public;
        icon?: string | null;
        name?: string | null;
      }
  );

// @deprecated
export type ExchangeAssetLabelInput = Pick<IAsset, 'label' | 'symbol' | 'name' | 'id'> & {
  type: IAssetType.Exchange;
  exchangeDetails: Label;
};

// @deprecated
export type AssetLabelInput =
  | ExchangeAssetLabelInput
  | PrivatePublicUnvestedAssetLabelInput
  | DerivativeAssetLabelInput;
