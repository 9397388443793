import { SvgIcon, type SvgIconProps } from '@mui/joy';
import type { ReactElement } from 'react';

import ActivityIcon from './svg/activity.svg?react';
import ArrowDownRightIcon from './svg/arrowDownRight.svg?react';
import ArrowUpRightIcon from './svg/arrowUpRight.svg?react';
import IncreasingArrowIcon from './svg/arrowUpRight.svg?react';
import BarChartIcon from './svg/barChart.svg?react';
import BookIcon from './svg/book.svg?react';
import BookmarkIcon from './svg/bookmark.svg?react';
import CameraIcon from './svg/camera.svg?react';
import CheckmarkIcon from './svg/checkmark.svg?react';
import CloseIcon from './svg/close.svg?react';
import CoinIcon from './svg/coin.svg?react';
import CorrelationIcon from './svg/correlation.svg?react';
import DiskIcon from './svg/disk.svg?react';
import DonutIcon from './svg/donut.svg?react';
import DownloadIcon from './svg/download.svg?react';
import ExpandIcon from './svg/expand.svg?react';
import GearIcon from './svg/gear.svg?react';
import GenieTextIcon from './svg/genieText.svg?react';
import GroupIcon from './svg/group.svg?react';
import InformationIcon from './svg/information.svg?react';
import KeyIcon from './svg/key.svg?react';
import LineChartUpwardTrendIcon from './svg/lineChartUpwardTrend.svg?react';
import ListIcon from './svg/list.svg?react';
import MenuIcon from './svg/menu.svg?react';
import MicroscopeIcon from './svg/microscope.svg?react';
import NetworkIcon from './svg/network.svg?react';
import NotificationActiveIcon from './svg/notificationActive.svg?react';
import NotificationEmptyIcon from './svg/notificationEmpty.svg?react';
import ParagraphIcon from './svg/paragraph.svg?react';
import PencilIcon from './svg/pencil.svg?react';
import PlantsIcon from './svg/plants.svg?react';
import PlaygroundIcon from './svg/playground.svg?react';
import QrCodeIcon from './svg/qrCode.svg?react';
import QuestionMarkIcon from './svg/questionMark.svg?react';
import RadarIcon from './svg/radar.svg?react';
import RefreshIcon from './svg/refresh.svg?react';
import ReversedStairsIcon from './svg/reversedStairs.svg?react';
import ScienceIcon from './svg/science.svg?react';
import SearchIcon from './svg/search.svg?react';
import ServerErrorIcon from './svg/serverError.svg?react';
import SignalIcon from './svg/signal.svg?react';
import SmoothLineChartIcon from './svg/smoothLineChart.svg?react';
import SyncIcon from './svg/sync.svg?react';
import TrashBinIcon from './svg/trashBin.svg?react';
import TrianglePointerIcon from './svg/trianglePointer.svg?react';
import WalletIcon from './svg/wallet.svg?react';
import WalletGroupIcon from './svg/walletGroup.svg?react';
import WarningIcon from './svg/warning.svg?react';
import WarningRoundIcon from './svg/warningRound.svg?react';
import AddCircleIcon from './svg/addCircle.svg?react';
import EditIcon from './svg/edit.svg?react';
import GenieIcon from './svg/genie.svg?react';
import GenieBorderIcon from './svg/genieBorder.svg?react';

export const Activity = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ActivityIcon}
  />
);

export const ArrowDownRight = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 12 12" // hardcoded based on genie icon viewbox
    {...props}
    component={ArrowDownRightIcon}
  />
);

export const ArrowUpRight = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 13 13" // hardcoded based on genie icon viewbox
    {...props}
    component={ArrowUpRightIcon}
  />
);

export const BarChart = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={BarChartIcon}
  />
);

export const Book = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={BookIcon}
  />
);

export const Bookmark = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={BookmarkIcon}
  />
);

export const Camera = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={CameraIcon}
  />
);

export const Checkmark = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 80 80" // hardcoded based on genie icon viewbox
    {...props}
    component={CheckmarkIcon}
  />
);

export const Close = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 24 24" // hardcoded based on genie icon viewbox
    {...props}
    component={CloseIcon}
  />
);

export const Coin = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={CoinIcon}
  />
);

export const Correlation = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 26 26" // hardcoded based on genie icon viewbox
    {...props}
    component={CorrelationIcon}
  />
);

export const Disk = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 24 30" // hardcoded based on genie icon viewbox
    {...props}
    component={DiskIcon}
  />
);

export const Donut = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="2 2 20 20" // hardcoded based on genie icon viewbox
    {...props}
    component={DonutIcon}
  />
);

export const Download = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 21 21" // hardcoded based on genie icon viewbox
    {...props}
    component={DownloadIcon}
  />
);

export const Expand = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ExpandIcon}
  />
);

export const Gear = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={GearIcon}
  />
);

export const GenieText = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 2863 542" // hardcoded based on genie icon viewbox
    {...props}
    component={GenieTextIcon}
  />
);

export const Group = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 28 29" // hardcoded based on genie icon viewbox
    {...props}
    component={GroupIcon}
  />
);

export const Information = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={InformationIcon}
  />
);

export const Key = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={KeyIcon}
  />
);

export const LineChartUpwardTrend = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={LineChartUpwardTrendIcon}
  />
);

export const List = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ListIcon}
  />
);

export const ExpandableMenu = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 18 12" // hardcoded based on genie icon viewbox
    {...props}
    component={MenuIcon}
  />
);

export const Microscope = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={MicroscopeIcon}
  />
);

export const Network = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={NetworkIcon}
  />
);

export const NotificationActive = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={NotificationActiveIcon}
  />
);

export const NotificationEmpty = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={NotificationEmptyIcon}
  />
);

export const Paragraph = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ParagraphIcon}
  />
);

export const Pencil = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={PencilIcon}
  />
);

export const Plants = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={PlantsIcon}
  />
);

export const Playground = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={PlaygroundIcon}
  />
);

export const QrCode = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 24 25" // hardcoded based on genie icon viewbox
    {...props}
    component={QrCodeIcon}
  />
);

export const QuestionMark = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={QuestionMarkIcon}
  />
);

export const Radar = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={RadarIcon}
  />
);

export const Refresh = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 16 16" // hardcoded based on genie icon viewbox
    {...props}
    component={RefreshIcon}
  />
);

export const ReversedStairs = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ReversedStairsIcon}
  />
);

export const Science = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ScienceIcon}
  />
);

export const Search = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 28 28" // hardcoded based on genie icon viewbox
    {...props}
    component={SearchIcon}
  />
);

export const ServerError = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={ServerErrorIcon}
  />
);

export const Signal = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 26 20" // hardcoded based on genie icon viewbox
    {...props}
    component={SignalIcon}
  />
);

export const SmoothLineChart = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={SmoothLineChartIcon}
  />
);

export const Sync = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 1024 1024" // hardcoded based on genie icon viewbox
    {...props}
    component={SyncIcon}
  />
);

export const TrashBin = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 16 18" // hardcoded based on genie icon viewbox
    {...props}
    component={TrashBinIcon}
  />
);

export const TrianglePointer = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 9 8" // hardcoded based on genie icon viewbox
    {...props}
    component={TrianglePointerIcon}
  />
);

export const Wallet = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={WalletIcon}
  />
);

export const Warning = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={WarningIcon}
  />
);

export const WarningRound = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 32 32" // hardcoded based on genie icon viewbox
    {...props}
    component={WarningRoundIcon}
  />
);

export const IncreasingArrow = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 13 13" // hardcoded based on genie icon viewbox
    {...props}
    component={IncreasingArrowIcon}
  />
);

export const WalletGroup = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 24 24" // hardcoded based on genie icon viewbox
    {...props}
    component={WalletGroupIcon}
  />
);

export const AddCircle = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 40 40" // hardcoded based on genie icon viewbox
    {...props}
    component={AddCircleIcon}
  />
);

export const Edit = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 18 18" // hardcoded based on genie icon viewbox
    {...props}
    component={EditIcon}
  />
);

export const GenieBorder = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 40 40" // hardcoded based on genie icon viewbox
    {...props}
    component={GenieBorderIcon}
  />
);

export const Genie = (props: SvgIconProps): ReactElement => (
  <SvgIcon
    viewBox="0 0 40 40" // hardcoded based on genie icon viewbox
    {...props}
    component={GenieIcon}
  />
);
