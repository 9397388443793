import PageShell from '../components/technical/PageShell/PageShell.tsx';
import NotificationDashboard from '../components/copilot/newsNotification/NotificationDashboard.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import PortfolioOptimizerDashboard from '../components/copilot/portfolioOptimizer/PortfolioOptimizerDashboard.tsx';
import {
  AssetOptimizationNameDocument,
  type IAssetOptimizationNameQuery,
  type IPortfolioDefNameQuery,
  type IPortfolioOptimizationNameQuery,
  type IStrategyQuery,
  PortfolioDefNameDocument,
  PortfolioOptimizationNameDocument,
  StrategyDocument,
} from '../generated/graphql.tsx';
import OptimizerResultDashboard from '../components/copilot/portfolioOptimizer/results/OptimizerResultDashboard.tsx';
import { OptimizationType } from '../components/copilot/portfolioOptimizer/optimization.utils.ts';
import AssetOptimizerWizardContainer from '../components/copilot/portfolioOptimizer/wizard/asset/AssetOptimizerWizard.tsx';
import PortfolioOptimizerWizardContainer from '../components/copilot/portfolioOptimizer/wizard/portfolio/PortfolioOptimizerWizard.tsx';
import PortfolioBuilderContainer from '../components/copilot/portfolioBuilder/PortfolioBuilder.tsx';
import OptionsStressTestOld from '../components/copilot/optionsStressTestOld/OptionsStressTestSimulator.tsx';
import UnifiedStressTestWizard from '../components/copilot/unifiedStressTest/wizard/UnifiedStressTestWizard.tsx';
import StrategyPage from '../components/copilot/strategy/StrategyDashboard.tsx';
import StrategiesList from '../components/copilot/strategy/StrategyList.tsx';
import PortfolioDefinitionList from '../components/copilot/lab/definition/PortfolioDefList.tsx';
import PortfolioBacktesting from '../components/copilot/lab/backtesting/PortfolioBacktesting.tsx';
import RebalancingRuleList from '../components/copilot/lab/definition/rebalancedPortfolio/rebalancingRules/RebalancingRuleList.tsx';
import CreateRebalancingRuleForm from '../components/copilot/lab/definition/rebalancedPortfolio/rebalancingRules/CreateRebalancingRuleForm.tsx';
import { Navigate, type Params, type RouteObject } from 'react-router';
import type { ApolloClient } from '@apollo/client';
import { parseIntParameter, queryOrNull } from './routerQueryClient.ts';
import ImportPositions from '../components/copilot/lab/definition/rebalancedPortfolio/importPositions/ImportPositions.tsx';
import { shellPadding } from '../components/technical/PageShell/PageShell.utils.ts';
import { Box } from '@mui/joy';
import { Suspense } from 'react';
import Loader from '../components/technical/Loader/Loader.tsx';
import StressTestDashboard from 'components/copilot/stressTestSimulator/StressTestDashboard.tsx';
import StressTestSimulatorContainer from 'components/copilot/stressTestSimulator/StressTestSimulatorContainer.tsx';

const portfolioDefinitionBreadcrumb = async (
  params: Params<string>,
  apolloClient: ApolloClient<unknown>
): Promise<string> => {
  const portfolioDefinitionId = params.portfolioDefinitionId;
  if (!portfolioDefinitionId) {
    return '?';
  }

  const res = await queryOrNull<IPortfolioDefNameQuery>(apolloClient, {
    query: PortfolioDefNameDocument,
    variables: { id: portfolioDefinitionId },
  });

  return res?.portfolio.definition.name ?? '?';
};

export const copilotRoutes = {
  handle: { title: 'Copilot', breadcrumb: 'Copilot' },
  path: 'copilot',
  children: [
    {
      handle: { breadcrumb: 'Notifications', title: 'Notifications' },
      path: 'notifications',
      index: true,
      element: (
        <PageShell>
          <NotificationDashboard />
        </PageShell>
      ),
    },
    {
      path: 'portfolio-optimizer',
      handle: { breadcrumb: 'Portfolio optimizer' },
      children: [
        createIndexRoute('optimizations'),
        {
          path: 'optimizations',
          handle: { breadcrumb: 'Optimizations' },
          children: [
            {
              index: true,
              element: (
                <PageShell>
                  <PortfolioOptimizerDashboard />
                </PageShell>
              ),
            },
            {
              path: 'asset',
              handle: { breadcrumb: 'Portfolio optimization' },
              children: [
                createIndexRoute('/app/copilot/portfolio-optimizer/optimizations'),
                {
                  path: ':optimizationId',
                  handle: {
                    breadcrumb: async (
                      params: Params<string>,
                      apolloClient: ApolloClient<unknown>
                    ): Promise<string> => {
                      const optimizationIdInt = parseIntParameter(params, 'optimizationId');
                      if (optimizationIdInt === null) {
                        return '?';
                      }

                      const res = await queryOrNull<IAssetOptimizationNameQuery>(apolloClient, {
                        query: AssetOptimizationNameDocument,
                        variables: {
                          optimizationId: optimizationIdInt,
                        },
                      });

                      return res?.portfolioOptimization.getOptimization.name ?? '?';
                    },
                  },
                  element: (
                    <PageShell>
                      <OptimizerResultDashboard optimizationType={OptimizationType.asset} />
                    </PageShell>
                  ),
                },
              ],
            },
            {
              path: 'portfolio',
              handle: { breadcrumb: 'Strategy optimization' },
              children: [
                createIndexRoute('/app/copilot/portfolio-optimizer/optimizations'),
                {
                  path: ':optimizationId',
                  handle: {
                    breadcrumb: async (
                      params: Params<string>,
                      apolloClient: ApolloClient<unknown>
                    ): Promise<string> => {
                      const optimizationIdInt = parseIntParameter(params, 'optimizationId');
                      if (optimizationIdInt === null) {
                        return '?';
                      }

                      const res = await queryOrNull<IPortfolioOptimizationNameQuery>(apolloClient, {
                        query: PortfolioOptimizationNameDocument,
                        variables: {
                          optimizationId: optimizationIdInt,
                        },
                      });

                      return res?.fundOptimization.getOptimization.name ?? '?';
                    },
                  },
                  element: (
                    <PageShell>
                      <OptimizerResultDashboard optimizationType={OptimizationType.portfolio} />
                    </PageShell>
                  ),
                },
              ],
            },
            {
              path: 'new-portfolio-optimization',
              handle: {
                breadcrumb: 'New portfolio optimization',
                docs: 'https://genie-crypto.atlassian.net/wiki/spaces/GENPUB/pages/774963201/Portfolio+Optimization',
              },
              element: (
                <PageShell>
                  <AssetOptimizerWizardContainer />
                </PageShell>
              ),
            },
            {
              path: 'new-strategy-optimization',
              handle: {
                breadcrumb: 'New strategy optimization',
                docs: 'https://genie-crypto.atlassian.net/wiki/spaces/GENPUB/pages/775815173/Strategy+Optimization',
              },
              element: (
                <PageShell>
                  <PortfolioOptimizerWizardContainer />
                </PageShell>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'portfolio-builder',
      handle: { breadcrumb: 'Portfolio builder' },
      element: (
        <PageShell>
          <PortfolioBuilderContainer />
        </PageShell>
      ),
    },
    {
      path: 'stress-test',
      handle: { breadcrumb: 'Stress test' },
      element: (
        <PageShell>
          <StressTestDashboard />
        </PageShell>
      ),
      children: [
        {
          path: 'spot',
          handle: { breadcrumb: 'Spot stress test' },
          element: <StressTestSimulatorContainer />,
        },
        {
          path: 'options',
          handle: { breadcrumb: 'Options stress test' },
          element: <OptionsStressTestOld />,
        },
        {
          path: 'unified',
          handle: { breadcrumb: 'Unified stress test' },
          element: <UnifiedStressTestWizard />,
        },
        { index: true, element: <Navigate to="spot" replace /> },
      ],
    },
    {
      path: 'strategies',
      handle: { breadcrumb: 'Strategies' },
      children: [
        {
          handle: {
            breadcrumb: async (params: Params<string>, apolloClient: ApolloClient<unknown>): Promise<string> => {
              const label = params.label;
              if (!label) {
                return '?';
              }

              const res = await queryOrNull<IStrategyQuery>(apolloClient, {
                query: StrategyDocument,
                variables: { label },
              });

              return res?.strategy.strategy?.name ?? '?';
            },
          },
          path: ':label',
          element: (
            <PageShell>
              <StrategyPage />
            </PageShell>
          ),
        },
        {
          index: true,
          element: (
            <PageShell>
              <StrategiesList />
            </PageShell>
          ),
        },
      ],
    },
    {
      path: 'lab',
      handle: { breadcrumb: 'Portfolio lab' },
      children: [
        createIndexRoute('portfolio'),
        {
          path: 'portfolio',
          children: [
            {
              index: true,
              element: (
                <Box height="100%" p={shellPadding}>
                  <Suspense fallback={<Loader />}>
                    <PortfolioDefinitionList />
                  </Suspense>
                </Box>
              ),
            },
            {
              path: ':portfolioDefinitionId/rebalancing-rules',
              handle: {
                breadcrumb: portfolioDefinitionBreadcrumb,
              },
              element: (
                <PageShell>
                  <RebalancingRuleList />
                </PageShell>
              ),
            },
            {
              path: ':portfolioDefinitionId/rebalancing-rules/new',
              handle: {
                breadcrumb: portfolioDefinitionBreadcrumb,
              },
              element: (
                <PageShell>
                  <CreateRebalancingRuleForm />
                </PageShell>
              ),
            },
            {
              path: ':portfolioDefinitionId/import-positions',
              handle: {
                breadcrumb: portfolioDefinitionBreadcrumb,
              },
              element: (
                <PageShell>
                  <ImportPositions />
                </PageShell>
              ),
            },
            {
              path: 'backtesting',
              handle: { breadcrumb: 'Backtesting' },
              element: (
                <PageShell>
                  <PortfolioBacktesting />
                </PageShell>
              ),
            },
          ],
        },
      ],
    },
    createIndexRoute('lab'),
  ],
} satisfies RouteObject;
