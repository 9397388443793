import { getNameWithTooltip } from 'components/metrics/MetricsData';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props';
import type { NotVerifiedAssetWithId } from '../AssetService.tsx';

const metrics = ['met:rolling_correlation-30', 'met:rolling_correlation-60', 'met:rolling_correlation-90'];
export const metricValues: SelectOption<string>[] = metrics.map((met) => ({
  label: getNameWithTooltip(met),
  key: met,
  value: met,
}));

export type CorrelationAsset = NotVerifiedAssetWithId & { symbol: string };
