import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useState, useMemo } from 'react';

import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import { createAssetSelectOptions } from '../AssetService';
import { type ILatestCorrelationsQuery, useLatestCorrelationsQuery } from '../../../../generated/graphql';
import CorrelationChart, {
  type LatestCorrelationData,
} from 'components/technical/charts/CorrelationChart/CorrelationChart';
import type { CorrelationAsset } from './Correlation.utils.ts';

interface LatestCorrelationContainerProps {
  metric: Label;
  supportedAssets: CorrelationAsset[];
  defaultAssets: CorrelationAsset[];
}

const transformQueryData = (queryData: ILatestCorrelationsQuery | undefined): LatestCorrelationData[] => {
  if (!queryData || !queryData.assets.twoAssetLatestMetrics) return [];
  return queryData.assets.twoAssetLatestMetrics.map((metric) => ({
    firstElement: {
      id: metric.primaryAsset.id,
      name: metric.primaryAsset.name ?? metric.primaryAsset.symbol,
    },
    secondElement: {
      id: metric.secondaryAsset.id,
      name: metric.secondaryAsset.name ?? metric.secondaryAsset.symbol,
    },
    value: metric.value,
  }));
};

const MAX_DEFAULT_ASSETS = 40;
const LatestCorrelationContainer = ({
  metric,
  supportedAssets,
  defaultAssets,
}: LatestCorrelationContainerProps): ReactElement => {
  const [selectedAssets, setSelectedAssets] = useState<CorrelationAsset[]>(defaultAssets.slice(0, MAX_DEFAULT_ASSETS));

  const assetOptions = useMemo(() => createAssetSelectOptions(supportedAssets), [supportedAssets]);

  const queryOutput = useLatestCorrelationsQuery({
    variables: {
      input: {
        metric,
        primaryAssets: selectedAssets.map((asset) => asset.id),
        secondaryAssets: selectedAssets.map((asset) => asset.id),
      },
    },
  });

  const chartData = transformQueryData(queryOutput.data);

  return (
    <Stack spacing={1}>
      <StaticMultiAutocomplete
        {...assetOptions}
        value={selectedAssets}
        width="xl2"
        onChange={(vals): void => setSelectedAssets(vals)}
        label="Assets"
      />
      <CorrelationChart data={chartData} loading={queryOutput.loading} error={queryOutput.error} />
    </Stack>
  );
};

export default LatestCorrelationContainer;
