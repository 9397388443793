import { Grid, Stack, Typography } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { maxBy } from 'lodash/fp';
import { type FunctionComponent, useState } from 'react';
import { formatPercentage } from 'components/formatter.utils';
import { range7Days } from 'components/predefinedDateRanges';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';
import TitleValueTile from 'components/technical/Tile/TitleValueTile';
import TitleWithChip from 'components/technical/Tile/TitleWithChip';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { type IAssetContributionsQuery, IContributionMethod, useAssetContributionsQuery } from 'generated/graphql';
import AssetContributionGrid from './AssetContributionGrid';
import { useReportAssetGroup, type UseReportAssetGroupResultLoaded } from '../../../UseReportAssetGroups';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import { getAssetName } from 'components/market/asset/AssetService';

type ContributionReport = IAssetContributionsQuery['portfolio']['performanceAssetContributions'];

export type ContributionRow = ContributionReport['assetContributions'][number];

type AssetAttributionReportProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  data: ContributionReport;
};

const AssetContribution: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(range7Days.value);

  const assetAttributionQueryResult = useDefaultErrorHandling(
    useAssetContributionsQuery({
      variables: {
        ...convertDateRangeToSinceToDate(dateRange),
        subAccountAssetFilters,
        contributionPeriodMethod: IContributionMethod.MultiPeriod,
      },
    })
  );

  const reportAssetGroup = useReportAssetGroup();

  if (!reportAssetGroup.loaded) {
    return reportAssetGroup.Fallback();
  }

  return (
    <Stack spacing={2} height="100%">
      <Stack direction="row" spacing={3} alignItems="center">
        <PredefinedDateRangeButtonsWithCalendar
          alignRight={false}
          defaultValue={range7Days}
          onChange={(val): void => setDateRange(val)}
        />
      </Stack>
      {assetAttributionQueryResult.loaded ? (
        <AssetAttributionReport
          reportAssetGroup={reportAssetGroup}
          data={assetAttributionQueryResult.data.portfolio.performanceAssetContributions}
        />
      ) : (
        <assetAttributionQueryResult.Fallback />
      )}
    </Stack>
  );
};

const AssetAttributionReport: FunctionComponent<AssetAttributionReportProps> = ({ data, reportAssetGroup }) => {
  const lastDayPortfolioPerformance = data.portfolioPerformance.at(-1)!;
  const assetContributionsWithLastFirstDay = data.assetContributions
    .filter((assetContribution) => assetContribution.dailySnapshotValues.length > 0)
    .map((assetContribution) => {
      return {
        asset: assetContribution.asset,
        dailySnapshotValues: assetContribution.dailySnapshotValues,
        firstDay: assetContribution.dailySnapshotValues[0]!,
        lastDay: assetContribution.dailySnapshotValues.at(-1)!,
      };
    });

  const maxContributionAsset = maxBy(
    (contribution) => contribution.lastDay.cumulativeContributionsToReturn,
    assetContributionsWithLastFirstDay
  );

  return (
    <Stack spacing={2} height="100%">
      <Grid container alignItems="stretch" spacing={1.5}>
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Portfolio TWR</Typography>}
          value={formatPercentage(lastDayPortfolioPerformance?.cumulativePortfolioTwr)}
        />
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Opportunistic Trading</Typography>}
          value={formatPercentage(lastDayPortfolioPerformance?.cumulativeOpportunisticTradingContribution)}
        />
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Total Contribution by Assets</Typography>}
          value={formatPercentage(lastDayPortfolioPerformance?.cumulativeTotalContributionByAsset)}
        />
        <TitleValueTile
          loading={false}
          title={
            maxContributionAsset ? (
              <TitleWithChip
                title="Best Contributor"
                change={maxContributionAsset.lastDay.cumulativeContributionsToReturn ?? undefined}
              />
            ) : undefined
          }
          value={maxContributionAsset ? getAssetName(maxContributionAsset.asset) : 'N/A'}
        />
      </Grid>
      <AssetContributionGrid
        assetContributions={assetContributionsWithLastFirstDay}
        reportAssetGroup={reportAssetGroup}
      />
    </Stack>
  );
};

export default AssetContribution;
