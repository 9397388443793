import dayjs, { type Dayjs } from 'dayjs';
import type {
  SeriesAreaOptions,
  SeriesArearangeOptions,
  SeriesAreasplinerangeOptions,
  SeriesBarOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesScatterOptions,
  SeriesSunburstOptions,
} from 'highcharts';
import * as Highcharts from 'highcharts';
import { bignumber } from 'mathjs';
import numbro from 'numbro';

import { UTC } from 'components/date.utils.ts';
import { DateTimeFormat, formatCash, formatDate, formatNumber, formatPercentage } from '../../../formatter.utils.ts';
import type { RefObject } from 'react';
import LightTheme from './LightTheme.ts';
import DarkTheme from './DarkTheme.ts';

export type HighchartsDataPointDefault = {
  x?: number;
  y?: number | null;
  value?: number | null;
  low?: number;
  high?: number;
  textValue?: string;
};
export type HighchartsDataPoint = HighchartsDataPointDefault | number | number[];

// x values can be empty for 'Bar' charts
type HighchartData<T extends HighchartsDataPoint> = {
  data: T[];
};

export type HighchartSeries<T extends HighchartsDataPoint = HighchartsDataPointDefault> =
  | (
      | (Omit<SeriesLineOptions, 'data'> & {
          data: HighchartData<T>['data'];
        })
      | (Omit<SeriesAreaOptions, 'data'> & HighchartData<T>)
      | (Omit<SeriesArearangeOptions, 'data'> & HighchartData<T>)
      | SeriesAreasplinerangeOptions
      | SeriesScatterOptions
      | (Omit<SeriesColumnOptions, 'data'> & HighchartData<T>)
      | (Omit<SeriesBarOptions, 'data'> & HighchartData<T>)
      | SeriesSunburstOptions
    )
  | Highcharts.SeriesHistogramOptions
  | Highcharts.SeriesBellcurveOptions
  | ({
      type: 'heatmap';
      borderWidth?: number;
      borderColor?: string;
      dataLabels: {
        enabled: boolean;
        format: string;
      };
    } & HighchartData<T>)
  | {
      visible: false;
      showInLegend: false;
      id: string;
      type?: undefined;
      data: number[];
    };

export type SelectedChartElement = { xValue: Dayjs };

export const DEFAULT_DATE_FORMAT = '{value:%e %b %Y}';
const DEFAULT_POINT_FORMAT =
  "<span style='color:{point.color}'>●</span> {point.series.name}: <b>{point.textValue}</b><br/>";

export const dateTimeAxisFormat = {
  xAxis: {
    type: 'datetime' as const,
    labels: {
      format: DEFAULT_DATE_FORMAT,
    },
  },
};

export const dateTimeExportFormat = (filename: string): { exporting: Highcharts.ExportingOptions } => {
  return {
    exporting: {
      filename: `${filename}_${formatDate(dayjs(), DateTimeFormat.FileDateTime, UTC)}`,
      csv: {
        dateFormat: '%Y-%m-%d',
        columnHeaderFormatter: (_item: unknown, key: string): string | boolean => {
          if (!key) {
            return 'Datetime (UTC)';
          }
          return false;
        },
      },
    },
  };
};

export const numberFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = (ctx): string => {
  return formatNumber(ctx.value, 'short');
};

export const cashFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = (ctx): string => {
  return formatCash(ctx.value, 'short');
};

const configurablePercentageFormatter = (ctx: { value: string | number }, mantissa: number): string => {
  const finalValue = typeof ctx.value === 'number' ? ctx.value * 100 : ctx.value;
  const text = numbro(finalValue).format({
    trimMantissa: false,
    mantissa: mantissa,
  });

  return `${text}%`;
};

export const percentageFormatter = (ctx: { value: string | number }): string => {
  return configurablePercentageFormatter(ctx, 2);
};

export const percentageFormatter4 = (ctx: { value: string | number }): string => {
  return configurablePercentageFormatter(ctx, 4);
};

export const getFormatter = (
  format: 'number' | 'cash' | 'percentage' | 'percentage_4'
): Highcharts.AxisLabelsFormatterCallbackFunction => {
  switch (format) {
    case 'percentage':
      return percentageFormatter;
    case 'percentage_4':
      return percentageFormatter4;
    case 'number':
      return numberFormatter;
    default:
      return cashFormatter;
  }
};

export const tooltipFormat = {
  tooltip: {
    pointFormat: DEFAULT_POINT_FORMAT,
  },
};

export const noYAxisTitle = {
  yAxis: {
    title: {
      text: undefined,
    },
  },
};

export const createMarkers = {
  marker: {
    enabled: true,
    radius: 2,
    symbol: 'circle',
  },
};

export const shortTooltipFormat = {
  tooltip: {
    pointFormat: "<span style='color:{point.color}'>●</span> <b>{point.textValue}</b><br/>",
  },
};

export const returnProbabilityTooltipFormatter = (params: {
  chart: { hoverPoint: Highcharts.Point | null };
  returnsNumber: number;
}): string => {
  const point = params.chart.hoverPoint as (Highcharts.Point & { x2: number; y: number }) | null;

  if (!point) {
    return '';
  }

  const returnNode = `Return: <b>${formatPercentage(point.x)} - ${formatPercentage(point.x2)}</b>`;

  const percentageNode = `Probability: <b>${formatPercentage(
    bignumber(point.y).div(params.returnsNumber).toNumber()
  )}</b>`;

  return `<span>${returnNode}<br/>${percentageNode}</span>`;
};

export const highChartsNeutralColorIndex = 0;
export const highChartsPositiveColorIndex = 1;
export const highChartsNegativeColorIndex = 2;
export const highChartAnnotationColor = 'var(--joy-palette-text-tertiary)';

export const inactivePointRadius = 3;

const dayMs = 1000 * 60 * 60 * 24;
export const bandOffset = dayMs * 0.25;

export const getHighchartColor = (colorScheme: 'dark' | 'light', index: number): string => {
  const defaultColors = colorScheme === 'light' ? LightTheme.colors : (DarkTheme.colors as string[]);
  if (!defaultColors) {
    return 'var(--joy-palette-background-level3)';
  }

  if (index < defaultColors.length) {
    return defaultColors[index];
  }

  return defaultColors[Math.max(0, index) % defaultColors.length];
};

export const getHighchartColorWithOpacity = (colorScheme: 'dark' | 'light', index: number, opacity: number): string => {
  const color = getHighchartColor(colorScheme, index);
  return Highcharts.color(color).setOpacity(opacity).get('rgba').toString();
};

export const addHoverHighlight = (colorScheme: 'dark' | 'light', chart?: Highcharts.Chart): void => {
  if (!chart?.hoverPoint) {
    return;
  }

  const xVal = chart.hoverPoint.x;

  chart.xAxis[0].addPlotBand({
    id: 'hover',
    color: getHighchartColorWithOpacity(colorScheme, 0, 0.5),
    from: xVal - bandOffset,
    to: xVal + bandOffset,
  });
};

export const removeHoverHighlight = (chart?: Highcharts.Chart): void => {
  chart?.xAxis[0].removePlotBand('hover');
};

export const defaultTooltipDateHeader = (text: string): string => `<span style="font-size: 0.8em">${text}</span>`;
export const defaultTooltipPointColor = (
  color: string | Highcharts.GradientColorObject | Highcharts.PatternObject | undefined
): string => `<span style='color:${color}'>●</span>`;

export const areaChartOpacity = 0.3;

export const highlightPoint = (colorScheme: 'dark' | 'light', chart: Highcharts.Chart): void => {
  if (!chart.hoverPoint) {
    return;
  }

  const xVal = chart.hoverPoint.x;
  // Remove selected point highlight
  chart.xAxis[0].removePlotBand('plot-band');

  // Add plot line (highlight selected point)
  chart.xAxis[0].addPlotBand({
    id: 'plot-band',
    color: getHighchartColorWithOpacity(colorScheme, 0, 0.5),
    from: xVal - dayMs * 0.25,
    to: xVal + dayMs * 0.25,
  });
};

const selectPoints = (chart: Highcharts.Chart): void => {
  // Deselect selected
  chart.getSelectedPoints().map((point) => point.select(false));
  // Select new ones
  chart.hoverPoints?.map((point) => point.select?.(true));
};

export const getChartClickEvent = (
  colorScheme: 'dark' | 'light',
  chart: Highcharts.Chart,
  onElementSelected?: (val: SelectedChartElement) => void,
  highlight?: boolean
): void => {
  if (!chart.hoverPoint) {
    return;
  }

  const xVal = chart.hoverPoint.x;

  if (highlight) {
    highlightPoint(colorScheme, chart);
  }

  selectPoints(chart);

  const selectedElement = {
    xValue: dayjs.unix(xVal / 1000),
  };

  if (onElementSelected) {
    onElementSelected(selectedElement);
  }
};

export type HighChartRef = {
  chart: Highcharts.Chart;
  container: RefObject<HTMLDivElement>;
};

export const getPointClickEvent = (
  colorScheme: 'dark' | 'light',
  event: Highcharts.PointInteractionEventObject & { target: { x?: number } | null },
  ref: HighChartRef | null,
  onElementSelected?: (val: SelectedChartElement) => void
): void => {
  if (!event || !event.target || !ref?.chart) {
    return;
  }

  const xVal = event.target.x!;
  const dayjsTimestamp = dayjs.unix(xVal / 1000);

  highlightPoint(colorScheme, ref.chart);

  const selectedElement = {
    xValue: dayjsTimestamp,
  };

  if (onElementSelected) {
    onElementSelected(selectedElement);
  }
};

export const heatmapBorderWidth = 0.1;
export const heatmapBorderColor = 'black';

export const markerRadius = 5;
export const chartSize = '450px';
export const defaultGapBetweenChartsPercentage = 5;
export const defaultXAxisChartsHeight = 15;
export const calculateMultiChartParams = ({
  items,
  gapBetweenChartsPercentage = defaultGapBetweenChartsPercentage,
  singleItemHeight,
  xAxisHeight = defaultXAxisChartsHeight,
  legendMaxHeight,
}: {
  items: number;
  gapBetweenChartsPercentage?: number;
  singleItemHeight: number;
  xAxisHeight?: number;
  legendMaxHeight: number;
}): {
  yAxis: (index: number) => {
    height: string;
    top: string;
    offset: number;
  };
  chartHeight: number;
  chartSpacingTop: number;
} => {
  const yAxisPercentageSpan = (100 - gapBetweenChartsPercentage * (items - 1)) / items;

  return {
    yAxis: (index) => ({
      height: `${yAxisPercentageSpan}%`,
      top: `${(yAxisPercentageSpan + gapBetweenChartsPercentage) * index}%`,
      offset: 0,
    }),
    chartHeight: legendMaxHeight + xAxisHeight + singleItemHeight * items,
    chartSpacingTop: 20,
  };
};

export const generateZones = (
  colorScheme: 'light' | 'dark',
  positiveColorIndex: number,
  negativeColorIndex: number,
  opacity = 0.8
): Highcharts.SeriesAreaOptions['zones'] => {
  return [
    {
      value: 0,
      color: getHighchartColorWithOpacity(colorScheme, negativeColorIndex, 1),
      fillColor: {
        linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
        stops: [
          [0, getHighchartColorWithOpacity(colorScheme, negativeColorIndex, opacity)], // Top color
          [0.4, getHighchartColorWithOpacity(colorScheme, negativeColorIndex, opacity * 0.4)], // Top color
          [1, getHighchartColorWithOpacity(colorScheme, negativeColorIndex, 0)], // Fade out
        ],
      },
    },
    {
      color: getHighchartColorWithOpacity(colorScheme, positiveColorIndex, 1),
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, getHighchartColorWithOpacity(colorScheme, positiveColorIndex, opacity)], // Top color
          [0.4, getHighchartColorWithOpacity(colorScheme, positiveColorIndex, opacity * 0.4)], // Top color
          [1, getHighchartColorWithOpacity(colorScheme, positiveColorIndex, 0)], // Fade out
        ],
      },
    },
  ];
};
