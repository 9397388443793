import { Box, Stack, Typography, Tooltip, IconButton } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { type Dispatch, type FunctionComponent, type SetStateAction, useMemo, useState } from 'react';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import GTable from 'components/technical/GTable/GTable';

import {
  type ISubFundsQuery,
  SubFundsDocument,
  useDeleteSubFundMutation,
  useUpdateSubFundMutation,
} from 'generated/graphql';
import ChangeSubFundAccountsDialog from './ChangeSubFundAccountsDialog.tsx';
import { AccountLabel } from '../account/AccountLabel.tsx';
import { DateTimeFormat, formatDate } from 'components/formatter.utils.ts';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import type { SubFund, SubFundCorrelationAssets } from './SubFundsDashboard.tsx';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import { sortBy } from 'lodash/fp';
import { Information } from 'components/technical/icons/index.tsx';
import AssetLabel from 'components/market/asset/AssetLabel.tsx';
import { DeleteOutline } from '@mui/icons-material';

const columnHelper = createColumnHelper<SubFund>();

type DimensionSubFundsTableProps = {
  dimensionSubFunds: SubFund[];
  existingSubFunds: Set<string>;
  accounts: ISubFundsQuery['portfolio']['accounts'];
  setSelectedDimension: Dispatch<SetStateAction<string | null>>;
  correlationAssets: SubFundCorrelationAssets;
  dimensions: string[];
};

const DimensionSubFundsTable: FunctionComponent<DimensionSubFundsTableProps> = ({
  dimensionSubFunds,
  existingSubFunds,
  accounts,
  correlationAssets,
  dimensions,
  setSelectedDimension,
}) => {
  const refetchFunds = { refetchQueries: [SubFundsDocument] };
  const [deleteSubFund] = useDeleteSubFundMutation(refetchFunds);
  const [updateSubFund] = useUpdateSubFundMutation(refetchFunds);

  const { showGraphqlError, showSuccessMessage } = useFeedback();

  const timezone = useUserTimezone();

  const [editRow, setEditRow] = useState<SubFund | null>(null);
  const [deleteRow, setDeleteRow] = useState<SubFund | null>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Sub-fund name',
        meta: {
          headerStyles: {
            minWidth: '130px',
          },
        },
        cell: (props: CellContext<SubFund, unknown>) => {
          const tooltipContent = (
            <Stack>
              <Typography>
                Added on: {formatDate(props.row.original.createdAt, DateTimeFormat.DateTime, timezone)}
              </Typography>
              <Typography>
                Last updated: {formatDate(props.row.original.updatedAt, DateTimeFormat.DateTime, timezone)}
              </Typography>
            </Stack>
          );

          return (
            <Stack direction="row" alignItems="center" spacing={0.4}>
              <Typography level="body-xs">{props.row.original.name}</Typography>
              <Tooltip title={tooltipContent}>
                <Information fontSize="sm" />
              </Tooltip>
            </Stack>
          );
        },
      }),
      columnHelper.display({
        header: 'Accounts',
        cell: (props: CellContext<SubFund, unknown>) => {
          const sortedAccounts = sortBy((a) => a.name, props.row.original.accounts);
          return (
            <Stack spacing={1} flexWrap="wrap" direction="row" width="400px" overflow="auto">
              {sortedAccounts.map((account) => (
                <AccountLabel wrap={false} plain account={account} key={account.id} size={IconVariant.MEDIUM} />
              ))}
            </Stack>
          );
        },
      }),
      columnHelper.accessor('referenceAsset', {
        header: 'Reference Asset',
        meta: {
          headerStyles: {
            width: '250px',
          },
        },
        cell: (cellProps: CellContext<SubFund, { id: string; name?: string | null | undefined }>) => (
          <AssetLabel asset={cellProps.row.original.referenceAsset} size={IconVariant.MEDIUM} />
        ),
      }),
      columnHelper.display({
        header: 'Actions',
        meta: {
          headerStyles: {
            width: '150px',
          },
          align: 'center',
        },
        cell: (props: CellContext<SubFund, unknown>) => {
          const subFundRow = props.row.original;
          if (subFundRow.isDefault) {
            return null;
          }

          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Tooltip title="Edit sub-fund">
                <IconButton
                  variant="plain"
                  color="primary"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditRow(subFundRow);
                  }}
                  aria-label="Edit sub-fund"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove sub-fund">
                <IconButton
                  variant="soft"
                  color="danger"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteRow(subFundRow);
                  }}
                  aria-label="Remove sub-fund"
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      }),
    ],
    [timezone]
  );

  const sorted = sortBy('name', dimensionSubFunds);

  return (
    <>
      <GTable<SubFund> fullHeight columns={columns} data={sorted} disablePagination />

      {/* Edit Dialog */}
      {editRow && (
        <ChangeSubFundAccountsDialog
          onClose={() => setEditRow(null)}
          existingSubFunds={existingSubFunds}
          correlationAssets={correlationAssets}
          accounts={accounts.filter(
            (account) =>
              !dimensionSubFunds.some(
                (subFund) =>
                  subFund !== editRow && !subFund.isDefault && subFund.accounts.some((acc) => acc.id === account.id)
              )
          )}
          initialState={editRow}
          handleFormSubmit={async (updatedSubFund, onErrorAndThrow): Promise<void> => {
            try {
              await updateSubFund({
                variables: {
                  input: {
                    oldName: editRow.name,
                    newName: updatedSubFund.name,
                    dimension: editRow.dimension,
                    accounts: updatedSubFund.accounts.map((account) => account.id),
                    subAccounts: [],
                    referenceAsset: updatedSubFund.referenceAsset?.id,
                  },
                },
              });
              showSuccessMessage('Sub-fund successfully updated');
              setEditRow(null);
            } catch (e) {
              onErrorAndThrow(e);
            }
          }}
        />
      )}

      {/* Delete Confirmation Dialog */}
      {deleteRow && (
        <ConfirmationDialog
          onClose={() => setDeleteRow(null)}
          onApprove={async (): Promise<void> => {
            try {
              await deleteSubFund({
                variables: {
                  subFund: deleteRow.name,
                },
              });
              if (dimensionSubFunds.length === 1) {
                setSelectedDimension(dimensions.find((dimension) => dimension !== deleteRow.dimension) ?? null);
              }
              showSuccessMessage(`Fund ${deleteRow.name} successfully deleted`);
              setDeleteRow(null);
            } catch (e) {
              showGraphqlError(e);
              setDeleteRow(null);
            }
          }}
        >
          {dimensionSubFunds.length === 1
            ? `Are you sure you want to remove sub-fund "${deleteRow.name}"? Removing the last sub-fund will also delete its associated dimension.`
            : `Are you sure you want to remove sub-fund "${deleteRow.name}"?`}
        </ConfirmationDialog>
      )}
    </>
  );
};

export default DimensionSubFundsTable;
