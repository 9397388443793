import { useContext } from 'react';

import { AuthnContext, type User } from './Authn.tsx';

export const useImpersonateUser = (): ((user: User) => void) => {
  const state = useContext(AuthnContext);
  if (state.initialized && state.user) {
    return state.impersonate;
  }

  return () => {
    throw new Error('Impersonation not available');
  };
};
