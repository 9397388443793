import { Modal, ModalDialog, Stack } from '@mui/joy';
import { type FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import FormSelectedChipsBoard from 'components/technical/form/FormSelectedChipsBoard';
import GButton from 'components/technical/inputs/GButton/GButton';
import { defaultRowSpacing } from 'components/StackSpacing';
import { Edit } from '@mui/icons-material';
import type { UserGroup } from './AssetGroupsDashboard';
import { createAssetSelectOptions, getAssetName } from '../AssetService';
import { sortBy } from 'lodash/fp';
import AssetLabel from '../AssetLabel';
import { IconVariant } from '../cryptocurrencies/CryptocurrenciesData';
import { isAssetLabelInput } from '../AssetLabelService.ts';
import FormInput from 'components/technical/form/FormInput.tsx';

type Asset = UserGroup['assets'][number];

const formSchema = yup.object({
  groupName: yup.string().required(),
  assets: yup.array().of(yup.mixed()).required().min(1),
});

type GroupAssetsFormState = {
  groupName: string;
  assets: Asset[];
};

type ChangeGroupAssetsDialogProps = {
  onClose: () => void;
  assets: Asset[];
  handleFormSubmit: (data: GroupAssetsFormState, graphQlErrorHandler: GraphQlErrorHandler) => Promise<void>;
  initialState: UserGroup;
};

const ChangeGroupAssetsDialog: FunctionComponent<ChangeGroupAssetsDialogProps> = (props) => {
  const initiallySelectedAssets = sortBy((a) => getAssetName(a), props.initialState.assets);
  const methods = useForm<GroupAssetsFormState>({
    resolver: gYupResolver(formSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      groupName: props.initialState.groupName,
      assets: initiallySelectedAssets,
    },
  });
  const selectedAssets = methods.watch('assets');
  const availableAssets = props.assets.filter((asset) => !selectedAssets.map((a) => a.id).includes(asset.id));

  const availableToAddAssetsOptions = createAssetSelectOptions(availableAssets.filter(isAssetLabelInput));

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [autocompleteClearKey, setAutocompleteClearKey] = useState(0);

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog layout="center" size="lg">
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => props.handleFormSubmit(data, onErrorAndThrow))}>
            <GDialogHeader>{methods.watch('groupName')}</GDialogHeader>
            <Stack spacing={3} alignItems="start" direction="column">
              <Stack direction="row" gap={3.5} width="100%">
                <FormInput
                  type="input"
                  showLabelAboveInput
                  placeholder="Group Name"
                  label="Group Name"
                  name="groupName"
                  autoComplete="off"
                  width="xl2"
                />
                <Stack direction="column" gap={3.5} width="100%">
                  <StaticSingleAutocomplete
                    value={null}
                    key={autocompleteClearKey} // to clear input after asset selected, value=null works only on blur too late
                    label="Assets"
                    placeholder="Search"
                    width="fullWidth"
                    showLabelAboveInput
                    {...availableToAddAssetsOptions}
                    showChipTooltips
                    menuWidth="xl3"
                    onChange={(assetToAdd) => {
                      if (assetToAdd) {
                        methods.setValue('assets', selectedAssets.concat(assetToAdd), {
                          shouldValidate: true,
                        });
                        setAutocompleteClearKey((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);
                      }
                    }}
                  />
                  <FormSelectedChipsBoard<GroupAssetsFormState>
                    name="assets"
                    label={`${props.initialState.groupName} Assets`}
                    placeholder="Assets"
                    height={300}
                    width="xl4"
                    getItemKey={(asset) => asset.id}
                    renderItem={(asset) => (
                      <AssetLabel asset={asset} link={false} size={IconVariant.MEDIUM} wrap={false} />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap={3.5} width="100%">
                <GraphQLApiFormErrorMessage />
                <Stack direction="row" justifyContent="end" width="100%" spacing={defaultRowSpacing}>
                  <GButton width="normal" variant="outlined" onClick={props.onClose}>
                    Cancel
                  </GButton>
                  <SubmitButton startDecorator={<Edit />} width="normal">
                    Save
                  </SubmitButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default ChangeGroupAssetsDialog;
