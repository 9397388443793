import type { ReactElement } from 'react';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';

const StressTestDashboard = (): ReactElement => {
  return (
    <NavigableTabs fullHeight>
      <NavigableTab link="/app/copilot/stress-test/spot" text="Spot" />
      <NavigableTab link="/app/copilot/stress-test/unified" text="Unified" />
      <NavigableTab link="/app/copilot/stress-test/options" text="Options" />
    </NavigableTabs>
  );
};

export default StressTestDashboard;
