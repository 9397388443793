import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/table-core';
import type { ReactElement, ReactNode } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import GButton from 'components/technical/inputs/GButton/GButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { type IUsersQuery, useUsersQuery } from '../../../generated/graphql';
import { useAuth } from '../../../UseAuth.tsx';
import { useImpersonateUser } from '../../../UseImpersonateUser.tsx';

type RowType = IUsersQuery['management']['users'][number];
const ImpersonationDashboard = (): ReactElement => {
  const { user } = useAuth();
  const impersonateUser = useImpersonateUser();
  const { loaded, data, Fallback } = useDefaultErrorHandling(useUsersQuery());
  if (!loaded) {
    return <Fallback />;
  }

  if (!user) {
    return <Message>No data</Message>;
  }

  const columnHelper = createColumnHelper<RowType>();
  const columns: ColumnDef<RowType>[] = [
    {
      header: 'Name',
      accessorFn: (user) => user.name ?? '-',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Groups',
      accessorFn: (user) => user.groups.join(', '),
    },
    {
      header: 'Cognito username',
      accessorKey: 'cognitoUsername',
    },
    {
      header: 'User id',
      accessorKey: 'id',
    },
    {
      header: 'Organization id',
      accessorKey: 'organization.id',
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '100px',
        },
      },
      cell: (props: CellContext<RowType, unknown>): ReactNode => {
        if (user.cognitoUsername === props.row.original.cognitoUsername) {
          return null;
        }

        return (
          <GButton
            onClick={(): void => {
              impersonateUser({
                ...props.row.original,
                name: props.row.original.name ?? null,
              });
              window.location.reload();
            }}
          >
            Impersonate
          </GButton>
        );
      },
    }),
  ];

  return (
    <SectionColumn>
      <HeaderBar title="Users" />
      <GTable columns={columns} data={data.management.users} disablePagination />
    </SectionColumn>
  );
};

export default ImpersonationDashboard;
