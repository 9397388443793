import { Divider, Grid, Stack, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
interface TwoThirdsLayoutProps {
  left: ReactNode;
  right: ReactNode;
  spacing?: number;
  title?: string;
  withDivider?: boolean;
}
export const TwoThirdsLayout = ({
  left,
  right,
  spacing = 1.5,
  title,
  withDivider = false,
}: TwoThirdsLayoutProps): ReactElement => {
  return (
    <Stack justifyContent="space-between" width="100%" alignItems="stretch">
      {title && <Typography level="title-md">{title}</Typography>}
      <Grid container spacing={spacing} paddingBottom={2}>
        <Grid lg={8} xs={11}>
          {left}
        </Grid>
        {withDivider && <Divider orientation="vertical" />}
        <Grid lg={4} xs={12}>
          {right}
        </Grid>
      </Grid>
    </Stack>
  );
};
