import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import type { BigNumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { fallbackAssetLabels } from 'components/portfolio/PortfolioAssets';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import CorrelationContainer from './CorrelationContainer';
import bigNumMath from '../../../../bigNumMath';
import { useCorrelationInputQuery } from '../../../../generated/graphql';
import { getPublicSpotPositionsWithValue } from '../../../portfolio/account/SubAccountPositionsService.ts';
import { isNil } from 'lodash/fp';
import type { CorrelationAsset } from './Correlation.utils.ts';

const CorrelationDashboard: FunctionComponent = () => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(useCorrelationInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const supportedAssets: CorrelationAsset[] = data.assets.feature;
  const positionsWithValues = getPublicSpotPositionsWithValue(data.portfolio.positions.positions);
  const groupedPositionsWithValue: { asset: CorrelationAsset; value: BigNumber }[] = Object.values(
    groupBy((pos) => pos.asset.id, positionsWithValues)
  ).map((positions) => ({
    asset: positions[0].asset,
    value: bigNumMath.sum(positions.map((pos) => pos.value)),
  }));

  const sortedPositions = sortBy((positions) => positions.value.toNumber(), groupedPositionsWithValue).reverse();
  const supportedAssetIds = new Set(supportedAssets.map((asset) => asset.id));
  const sortedPortfolioAssets = sortedPositions
    .map((pos) => pos.asset)
    .filter((asset) => supportedAssetIds.has(asset.id));
  const fallbackAssets = fallbackAssetLabels
    .map((label) => supportedAssets.find((supportedAsset) => supportedAsset.label === label))
    .filter((asset): asset is CorrelationAsset => !isNil(asset));
  const defaultAssets: CorrelationAsset[] = sortedPortfolioAssets.length === 0 ? fallbackAssets : sortedPortfolioAssets;

  return <CorrelationContainer supportedAssets={supportedAssets} defaultAssets={defaultAssets} />;
};

export default CorrelationDashboard;
