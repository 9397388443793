import { Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ReactElement, useCallback, useMemo, useState } from 'react';
import ActionsChartWithTitle from 'components/ActionsChartWithTitle.tsx';
import { getDefaultRange } from 'components/predefinedDateRanges';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import { Select } from 'components/technical/inputs';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';

import type { PublicAsset } from '../../../Asset.types';
import { createAssetSelectOptions } from '../../../AssetService';
import { type CorrelationAsset, metricValues } from '../../../correlation/Correlation.utils';
import HistoricalCorrelation from '../../../correlation/HistoricalCorrelation';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';

const AssetCorrelationContainer = ({
  asset,
  supportedAssets,
  defaultAssets,
}: {
  asset: PublicAsset;
  supportedAssets: CorrelationAsset[];
  defaultAssets: CorrelationAsset[];
}): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [selectedAssets, setSelectedAssets] = useState<CorrelationAsset[]>(defaultAssets);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [metric, setMetric] = useState(metricValues[0].value);
  const assetOptions = useMemo(() => createAssetSelectOptions(supportedAssets), [supportedAssets]);

  const { id: assetId, symbol: assetSymbol } = asset;

  const labelProvider = useCallback(
    (firstAsset: { symbol: string; id: string }, secondAsset: { symbol: string; id: string }): string => {
      const otherAssetSymbol =
        [firstAsset, secondAsset].find((labelAsset) => labelAsset.id !== assetId)?.symbol ?? assetSymbol;
      return otherAssetSymbol;
    },
    [assetId, assetSymbol]
  );

  return (
    <>
      <ActionsContextProvider>
        <ActionsChartWithTitle title={`Historical ${asset.name?.toLowerCase()} correlations`} paper>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0.5} flexWrap="wrap">
            <StaticMultiAutocomplete
              {...assetOptions}
              value={selectedAssets}
              width="xl2"
              onChange={(vals): void => setSelectedAssets(vals)}
              label="Assets"
            />
            <Select
              label="Metric"
              value={metric}
              onChange={(value): void => setMetric(value)}
              options={metricValues}
              width="normal"
            />
            <PredefinedDateRangeButtonsWithCalendar
              defaultValue={defaultDateRange}
              onChange={(val): void => setDateRange(val)}
            />
          </Stack>
          <HistoricalCorrelation
            queryInput={{
              input: {
                primaryAssets: [asset.id],
                secondaryAssets: selectedAssets.map((asset) => asset.id),
                metric,
              },
              filters: {
                date: convertDateRangeToSinceToDate(dateRange),
              },
            }}
            labelProvider={labelProvider}
            selectedAssets={[asset, ...selectedAssets]}
            filename={`historical-${asset.symbol}-correlation`}
          />
        </ActionsChartWithTitle>
      </ActionsContextProvider>
    </>
  );
};

export default AssetCorrelationContainer;
