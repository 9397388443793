import type { ReactNode } from 'react';

import type { MetricData, MetricParams } from './MetricsData.types';

const days = [30, 60, 90, 365];
const percentages = [95, 99];

const rollingAssetPercentageMetric = (
  name: string,
  labelProvider: (day: number, percentage: number) => string,
  tooltipProvider: (percentage: number) => ReactNode
): { [key: string]: MetricData } => {
  return Object.fromEntries(
    days.flatMap((day) =>
      percentages.map((percentage) => [
        `met:rolling_${labelProvider(day, percentage)}`,
        {
          name: (_params?: MetricParams, withoutDay?: boolean) =>
            withoutDay ? `${name} ${percentage}%` : `${name} ${percentage}% (${day}D)`,
          format: 'percentage',
          tooltip: tooltipProvider(percentage),
        },
      ])
    )
  );
};

const rollingAssetMetric = (
  name: string,
  format: 'cash' | 'number' | 'percentage',
  labelProvider: (day: number) => string,
  tooltipProvider: () => ReactNode
): { [key: string]: MetricData } => {
  return Object.fromEntries(
    days.map((day) => [
      `met:rolling_${labelProvider(day)}`,
      {
        name: (_params?: MetricParams, withoutDay?: boolean) => (withoutDay ? name : `${name} (${day}D)`),
        format,
        tooltip: tooltipProvider(),
      },
    ])
  );
};

export const INDEX_PRICE_METRIC = 'met:index_price';
export const OPEN_INTEREST_AMOUNT_METRIC = 'met:open_interest_amount';
export const NEXT_FUNDING_RATE_METRIC = 'met:next_funding_rate';
export const LONG_RATE_METRIC = 'met:long_rate';
export const SHORT_RATE_METRIC = 'met:short_rate';
export const TOTAL_VOLUME = 'met:volume-24h';
export const ACTIVE_ADDRESSES_METRIC = 'met:active_address_cnt';
export const MAX_DRAWDOWN = 'met:expanding_max_drawdown';
export const MAX_DRAWDOWN_1Y = 'met:rolling_max_drawdown-365';
export const DRAWDOWN = 'met:expanding_drawdown';
export const metricsData: Record<string, MetricData> = {
  'met:total_val_locked': {
    name: 'Total value locked',
    format: 'cash',
    tooltip: (
      <>
        Total value locked inside a platform / protocol&apos;s own contracts by users. This does not include: Staking,
        Pool2, Borrows and liquid staking.
      </>
    ),
  },
  'met:transfer_value_adj_native': {
    name: 'Transfer value adjusted (native)',
    format: 'number',
  },
  'met:market_cap': {
    name: 'Market cap',
    format: 'cash',
    tooltip: <>The total market value of a cryptocurrency&apos;s circulating supply</>,
  },
  ...rollingAssetPercentageMetric(
    'Cvar',
    (day, percentage) => `cvar${percentage}-${day}`,
    (percentage) => (
      <>Is the average loss that you can expect in the worst {100 - percentage}% of the cases, 1 day in the future</>
    )
  ),
  ...rollingAssetPercentageMetric(
    'Var',
    (day, percentage) => `var${percentage}-${day}`,
    (percentage) => <>Is the max loss that you can expect in {percentage}% of the cases, 1 day in the future</>
  ),
  ...rollingAssetMetric(
    'Down side risk',
    'percentage',
    (day) => `down_side_risk-${day}`,
    () => (
      <>
        The standard deviation of negative daily returns in a given period. Positive returns are counted with a value of
        0
      </>
    )
  ),
  ...rollingAssetMetric(
    'Sortino',
    'number',
    (day) => `sortino-${day}`,
    () => <>The ratio between the average daily returns of an asset and its downside risk</>
  ),
  ...rollingAssetMetric(
    'Sharpe',
    'number',
    (day) => `sharpe-${day}`,
    () => <>The ratio between the average daily returns of an asset and its volatility in a given period</>
  ),
  ...rollingAssetMetric(
    'Volatility',
    'percentage',
    (day) => `volatility-${day}`,
    () => <>Is the standard deviation of daily returns in the indicated period</>
  ),
  ...rollingAssetMetric(
    'Beta BTC',
    'number',
    (day) => `beta_btc-${day}`,
    () => <>Is how much your portfolio value is expected to change when the value of BTC increases by 1%</>
  ),
  ...rollingAssetMetric(
    'Beta ETH',
    'number',
    (day) => `beta_eth-${day}`,
    () => <>Is how much your portfolio value is expected to change when the value of ETH increases by 1%</>
  ),
  ...rollingAssetMetric(
    'Beta SOL',
    'number',
    (day) => `beta_sol-${day}`,
    () => <>Is how much your portfolio value is expected to change when the value of SOL increases by 1%</>
  ),
  'met:current_supply': {
    name: 'Current supply',
    format: 'number',
    tooltip: (
      <>
        The sum of all tokens ever created and currently issued. For account-based protocols, the metric includes only
        accounts with positive balances
      </>
    ),
  },
  'met:circulating_supply': {
    name: 'Circulating supply',
    format: 'number',
    tooltip: (
      <>
        Circulating Supply is the amount circulating within the market. The value includes only coins that are able to
        be traded, Coins that are locked, escrowed, reserved are not counted.
      </>
    ),
  },
  'met:max_supply': {
    name: 'Max supply',
    format: 'number',
    tooltip: <>Max Supply is the maximum amount of coins that will exist within the cryptocurrency platform</>,
  },
  'met:volume-24h': {
    name: '24-hour volume',
    format: 'cash',
    tooltip: <>Volume of the assets traded across exchanges in the last 24h</>,
  },
  [ACTIVE_ADDRESSES_METRIC]: {
    name: 'Active addresses',
    format: 'number',
    tooltip: (
      <>
        The sum of all unique addresses that were active in the network on that day. The value includes ledgers who
        acted as destination or source on a given change in the day. Individual addresses are counted only once per day,
        even if active in multiple transactions during the day.
      </>
    ),
  },
  'met:network_val_adj_transfer_val': {
    name: 'NVT',
    format: 'number',
    tooltip: (
      <>
        The ratio of the network value (or market capitalization, current supply) divided by the adjusted transfer value
      </>
    ),
  },
  'met:liquidity': {
    name: 'Liquidity',
    format: 'cash',
  },
  'met:flow_in_all_exchange_ntv': {
    name: 'Exchange inflow (native tokens)',
    format: 'number',
  },
  'met:flow_in_all_exchange_usd': {
    name: 'Exchange inflow (USD)',
    format: 'cash',
  },
  'met:flow_out_all_exchange_ntv': {
    name: 'Exchange outflow (native tokens)',
    format: 'number',
  },
  'met:flow_out_all_exchange_usd': {
    name: 'Exchange outflow (USD)',
    format: 'cash',
  },
  'met:rolling_correlation-30': {
    name: 'Correlation (30D)',
    format: 'number',
  },
  'met:rolling_correlation-60': {
    name: 'Correlation (60D)',
    format: 'number',
  },
  'met:rolling_correlation-90': {
    name: 'Correlation (90D)',
    format: 'number',
  },
  ...rollingAssetMetric(
    'Kurtosis',
    'number',
    (day) => `kurtosis-${day}`,
    () => (
      <>
        A measure of how thick the tails of the returns of your portfolio are. A valuable of 3 corresponds to gaussian
        tails.
      </>
    )
  ),
  ...rollingAssetMetric(
    'Skewness',
    'number',
    (day) => `skewness-${day}`,
    () => (
      <>
        A measure of the asymmetry of the returns of your portfolio. Positive skewness means the longer tail is the one
        with large positive returns.
      </>
    )
  ),
  ...rollingAssetMetric(
    'Momentum',
    'percentage',
    (day) => `momentum-${day}`,
    () => <>The cumulative returns of the assets over the specified timeframe</>
  ),
  ...rollingAssetMetric(
    'Idiosyncratic volatility',
    'percentage',
    (day) => `idio_vol-${day}`,
    () => <>The dispersion of the returns of the assets vs the returns of the market in the specified timeframe</>
  ),
  [INDEX_PRICE_METRIC]: {
    name: 'Index price',
    format: 'cash',
  },
  [NEXT_FUNDING_RATE_METRIC]: {
    name: 'Next funding rate',
    format: 'percentage_4',
  },
  [OPEN_INTEREST_AMOUNT_METRIC]: {
    name: 'Open interest amount',
    format: 'cash',
  },
  [LONG_RATE_METRIC]: {
    name: 'Long rate',
    format: 'percentage',
  },
  [SHORT_RATE_METRIC]: {
    name: 'Short rate',
    format: 'percentage_4',
  },
  [MAX_DRAWDOWN]: {
    name: 'Max drawdown',
    format: 'percentage',
    tooltip: "The max percentage loss from the asset's maximum price to the subsequent minimum price",
  },
  [MAX_DRAWDOWN_1Y]: {
    name: 'Max drawdown (1Y)',
    format: 'percentage',
    tooltip:
      "The max percentage loss from the asset's maximum price to the subsequent minimum price within a 365 days window",
  },
  [DRAWDOWN]: {
    name: 'Drawdown',
    format: 'percentage',
    tooltip: "The percentage loss from the asset's maximum price to the subsequent minimum price",
  },
  'met:address_balance_1in1m_count': {
    name: 'Addresses with balance ≥ 1 in 1M',
    format: 'number',
    tooltip: 'Number of addresses holding at least one in a million of the total circulating supply',
  },
  'met:address_balance_count': {
    name: 'Addresses with balance',
    format: 'number',
    tooltip: 'Total number of addresses with a non-zero balance',
  },
  'met:asset_completion_time': {
    name: 'Asset completion time',
    format: 'number',
    tooltip: 'Average time for an asset transaction to be considered complete',
  },
  'met:realized_cap_usd': {
    name: 'Realized cap (USD)',
    format: 'cash',
    tooltip: 'The realized capitalization in USD',
  },
  'met:fee_mean_native': {
    name: 'Mean fee (native)',
    format: 'number',
    tooltip: 'Average transaction fee in native currency',
  },
  'met:fee_mean_usd': {
    name: 'Mean fee (USD)',
    format: 'cash',
    tooltip: 'Average transaction fee in USD',
  },
  'met:fee_total_usd': {
    name: 'Total fees (USD)',
    format: 'cash',
    tooltip: 'Total transaction fees collected in USD',
  },
  'met:hash_rate': {
    name: 'Hash rate',
    format: 'number',
    tooltip: 'Current hash rate of the network',
  },
  'met:hash_rate_30d': {
    name: 'Hash rate (30D)',
    format: 'number',
    tooltip: '30-day moving average of the network hash rate',
  },
  'met:issuance_continuous_native': {
    name: 'Continuous issuance (native)',
    format: 'number',
    tooltip: 'Rate of continuous token issuance in native currency',
  },
  'met:network_distribution_factor': {
    name: 'Network distribution factor',
    format: 'number',
    tooltip: 'Measure of how well-distributed the network is',
  },
  'met:revenue_hash_rate_native': {
    name: 'Revenue per hash rate (native)',
    format: 'number',
    tooltip: 'Mining revenue per unit of hash rate in native currency',
  },
  'met:revenue_hash_rate_usd': {
    name: 'Revenue per hash rate (USD)',
    format: 'cash',
    tooltip: 'Mining revenue per unit of hash rate in USD',
  },
  'met:revenue_native': {
    name: 'Revenue (native)',
    format: 'number',
    tooltip: 'Total network revenue in native currency',
  },
  'met:revenue_usd': {
    name: 'Revenue (USD)',
    format: 'cash',
    tooltip: 'Total network revenue in USD',
  },
  'met:stock_to_flow_ratio': {
    name: 'Stock-to-flow ratio',
    format: 'number',
    tooltip: 'Ratio of the current stock of an asset to the flow of new production',
  },
  'met:supply_active_1d': {
    name: 'Active supply (1D)',
    format: 'number',
    tooltip: 'Amount of supply that has moved in the last 24 hours',
  },
  'met:supply_top_10_percent': {
    name: 'Supply in top 10% addresses',
    format: 'number',
    tooltip: 'Percentage of total supply held by the top 10% of addresses',
  },
  'met:supply_top_1_percent': {
    name: 'Supply in top 1% addresses',
    format: 'number',
    tooltip: 'Percentage of total supply held by the top 1% of addresses',
  },
  'met:supply_free_float': {
    name: 'Free float supply',
    format: 'number',
    tooltip: 'Amount of supply available for trading',
  },
  'met:supply_miner_0hop_all_native': {
    name: 'Miner supply (0-hop, native)',
    format: 'number',
    tooltip: 'Supply held by miners in native currency (0-hop)',
  },
  'met:supply_miner_0hop_all_usd': {
    name: 'Miner supply (0-hop, USD)',
    format: 'cash',
    tooltip: 'Supply held by miners in USD (0-hop)',
  },
  'met:transaction_count': {
    name: 'Transaction count',
    format: 'number',
    tooltip: 'Total number of transactions',
  },
  'met:transaction_count_second': {
    name: 'Transactions per second',
    format: 'number',
    tooltip: 'Number of transactions per second',
  },
  'met:transfer_count': {
    name: 'Transfer count',
    format: 'number',
    tooltip: 'Total number of transfers',
  },
  'met:transfer_value_adjusted_native': {
    name: 'Adjusted transfer value (native)',
    format: 'number',
    tooltip: 'Adjusted value of transfers in native currency',
  },
  'met:transfer_value_adjusted_usd': {
    name: 'Adjusted transfer value (USD)',
    format: 'cash',
    tooltip: 'Adjusted value of transfers in USD',
  },
  'met:velocity_1year': {
    name: 'Velocity (1Y)',
    format: 'number',
    tooltip: 'Velocity of money over the past year',
  },
};
