import HighChartsWrapper from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import {
  DEFAULT_DATE_FORMAT,
  type HighchartSeries,
  numberFormatter,
  percentageFormatter,
  tooltipFormat,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import type { AxisLabelsFormatterCallbackFunction } from 'highcharts';
import dayjs from 'dayjs';
import { formatNumber, formatPercentage } from '../../../formatter.utils.ts';
import type { BigNumber } from 'mathjs';
import Stack from '@mui/joy/Stack';
import type * as Highcharts from 'highcharts';
import { Typography } from '@mui/joy';
import type { SubFundTwrRow } from './SubFundPnlResult.tsx';

export type SupportedTwrFields = 'balance' | 'weight' | 'value';

export const tooltipFormatter: Record<SupportedTwrFields, AxisLabelsFormatterCallbackFunction> = {
  balance: numberFormatter,
  weight: percentageFormatter,
  value: numberFormatter,
};

export const valueFormatter: Record<
  SupportedTwrFields,
  (value: number | undefined | string | null | BigNumber) => string
> = {
  balance: formatNumber,
  weight: formatPercentage,
  value: formatNumber,
};

const calculateOptions = (field: SupportedTwrFields): Highcharts.Options => {
  return {
    exporting: {
      enabled: false,
    },
    ...tooltipFormat,
    xAxis: {
      type: 'datetime' as const,
      labels: {
        format: DEFAULT_DATE_FORMAT,
      },
    },
    yAxis: {
      labels: {
        formatter: tooltipFormatter[field],
      },
      title: {
        text: undefined,
      },
    },
  };
};

const calculateChartData = (data: SubFundTwrRow[], twrField: SupportedTwrFields): HighchartSeries[] => {
  return data.map(({ subFund, twr }) => {
    return {
      name: subFund.name,
      data: twr.map((twrDay) => ({
        x: dayjs.utc(twrDay.date.toString()).valueOf(),
        y: twrDay.return[twrField],
        textValue: valueFormatter[twrField](twrDay.return[twrField]),
      })),
      type: 'line' as const,
    };
  });
};

const SubFundPerformanceLineChart = ({
  data,
  twrField,
  title,
}: { data: SubFundTwrRow[]; twrField: SupportedTwrFields; title: string }) => {
  return (
    <Stack gap={2}>
      <Typography level="body-xs">{title}</Typography>
      <HighChartsWrapper
        data={data}
        loading={false}
        calculateOptions={() => calculateOptions(twrField)}
        calculateChartData={(data) => calculateChartData(data, twrField)}
      />
    </Stack>
  );
};

export default SubFundPerformanceLineChart;
