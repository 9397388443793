import { useOrganizationFeaturesSuspenseQuery } from 'generated/graphql';
import { useMemo } from 'react';

const MULTI_LEG_FEATURE_NAME = 'multileg-transactions';
const ENABLED = 'ENABLED';

/**
 * Custom hook to access organization settings.
 * Currently checks if the multileg-transactions feature is enabled.
 * Can be extended to include more features.
 */
export const useOrganizationSettings = () => {
  const { data } = useOrganizationFeaturesSuspenseQuery();

  const isMultiLegEnabled = useMemo(() => {
    if (!data) return false;

    const features = data.management.features;
    const nameToStatusFeatures = new Map(features.map((feature) => [feature.name, feature.status]));
    return nameToStatusFeatures.get(MULTI_LEG_FEATURE_NAME) === ENABLED;
  }, [data]);

  return {
    isMultiLegEnabled,
  };
};
