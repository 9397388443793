import { Box, Stack } from '@mui/joy';
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment, type ReactElement, useRef, useState } from 'react';
import { createPagination, showPagination } from './GTable.paginationUtils';
import type { GTableProps } from './GTable.props';
import {
  calculateExtraRows,
  calculatePaginationProps,
  calculateSortingProps,
  calculateState,
  createTableElementProps,
  isDynamic,
} from './GTable.utils';
import GTableCell from './GTableCell/GTableCell';
import { GTableHead } from './GTableHead';
import { GTableFilters } from './GTableFilters.tsx';
import { transitionDuration } from '../../../theme/consts.ts';
import isNil from 'lodash/fp/isNil';

export { default as GTableCell } from './GTableCell/GTableCell';
export { default as GTableSkeleton } from './GTableSkeleton';

const GTable = <ROW,>(props: GTableProps<ROW>): ReactElement => {
  const [activeFilters, setActiveFilters] = useState(
    (props.filters ?? []).filter((filter) => filter.defaultFilter).map((filter) => filter.label)
  );

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  const table = useReactTable<ROW>({
    columns: props.columns,
    data: props.data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableExpanding: !!props.detailRender,
    getRowId: (originalRow: ROW, index: number): string => {
      if (!props.getRowId) {
        return index.toString();
      }

      return props.getRowId(originalRow);
    },
    defaultColumn: {
      enableSorting: isDynamic(props) ? false : undefined,
    },
    state: {
      ...calculateState(props),
    },
    ...calculateSortingProps(props),
    ...calculatePaginationProps(props),
  });

  const extraRows = calculateExtraRows(props, table);
  const rowBackground = 'var(--joy-palette-background-surface)';
  const expandedTopRowBackground = 'var(--joy-palette-background-level1)';
  const transitionTimeAndStyle = `${transitionDuration.Short} ease-in`;
  const radius = 'var(--joy-radius-sm)';

  const pagination = showPagination(props);
  const showTop = (props.filters ?? []).length > 0 || props.topBar || pagination;
  const children = (
    <Stack gap={2} minHeight={0} maxHeight={'100%'}>
      {showTop && (
        <Stack gap={4}>
          <GTableFilters
            filters={props.filters ?? []}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
          {createPagination(table, props, props.hideRowsPerPage ?? false)}
        </Stack>
      )}
      <Box
        sx={{
          width: '100%',
          minHeight: 0,
          maxHeight: props.maxHeight,
          overflow: props.stickyHeader && !props.fullHeight && isNil(props.maxHeight) ? 'initial' : 'auto',
        }}
      >
        <Box component={'table'} sx={createTableElementProps()} ref={tableWrapperRef}>
          <GTableHead
            backgroundColor={props.headerBackground}
            table={table}
            hideHeaders={props.hideHeaders}
            hideFirstLastPadding={props.hideFirstLastPadding ?? props.hidePadding}
            sticky={props.stickyHeader}
            padding={props.cellPadding}
            tableWrapperRef={tableWrapperRef}
          />
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex, allRows) => {
              return (
                <Fragment key={row.id}>
                  <tr
                    style={{
                      background: row.getIsExpanded() ? expandedTopRowBackground : rowBackground,
                      transition: `background ${transitionTimeAndStyle}`,
                      cursor: props.detailRender ? 'pointer' : 'auto',
                    }}
                    onClick={() => {
                      if (props.detailRender) {
                        row.toggleExpanded();
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell, i, cells) => {
                      const isFirstCell = i === 0;
                      const isLastCell = i === cells.length - 1;
                      return (
                        <td
                          key={cell.id}
                          style={{
                            ...cell.column.columnDef.meta?.cellStyles,
                            border: '1px solid transparent',
                            borderTopLeftRadius: isFirstCell ? radius : 0,
                            borderTopRightRadius: isLastCell ? radius : 0,
                            borderBottomLeftRadius: isFirstCell && !row.getIsExpanded() ? radius : 0,
                            borderBottomRightRadius: isLastCell && !row.getIsExpanded() ? radius : 0,
                          }}
                        >
                          <div
                            style={{
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            <GTableCell
                              align={cell.column.columnDef.meta?.align}
                              first={i === 0}
                              padding={props.cellPadding}
                              last={i + 1 === row.getVisibleCells().length}
                              hideFirstLastPadding={props.hideFirstLastPadding ?? props.hidePadding}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </GTableCell>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  {props.detailRender && (
                    <tr>
                      <td
                        colSpan={row.getVisibleCells().length}
                        style={{
                          background: expandedTopRowBackground,
                          border: row.getIsExpanded() ? `1px solid ${expandedTopRowBackground}` : 'none',
                          borderBottomLeftRadius: radius,
                          borderBottomRightRadius: radius,
                        }}
                      >
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateRows: row.getIsExpanded() ? '1fr' : '0fr',
                            transition: `grid-template-rows ${transitionTimeAndStyle}`,
                          }}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                            }}
                          >
                            <div
                              style={{
                                padding: '1rem',
                              }}
                            >
                              <div
                                style={{
                                  background: 'var(--joy-palette-background-body)',
                                  borderRadius: radius,
                                  padding: '1rem',
                                }}
                              >
                                <props.detailRender row={row} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {rowIndex !== allRows.length - 1 && !props.hideBorders && (
                    <tr>
                      <Box component={'td'} colSpan={row.getVisibleCells().length} sx={{ height: '0.5rem' }}></Box>
                    </tr>
                  )}
                </Fragment>
              );
            })}
            {extraRows}
          </tbody>
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Stack
      spacing={1}
      sx={{
        height: props.fullHeight ? '100%' : undefined,
        minHeight: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: props.fullHeight ? '100%' : undefined,
          padding: props.hidePadding ? 0 : undefined,
          rowGap: 0,
          ...(props.hideShadow
            ? {
                boxShadow: 'none',
              }
            : {}),
          minHeight: 0,
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default GTable;
