import { useSubFundPnlSuspenseQuery } from '../../../../generated/graphql.tsx';
import { isNil, sortBy, uniqBy } from 'lodash/fp';
import { formatISODate } from '../../../formatter.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import { Card, Grid, Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import SubFundPerformanceLineChart from './SubFundPerformanceLineChart.tsx';
import SubFundPnlGrid from './SubFundPnlGrid.tsx';
import type { ITimeWeightedReturnType } from '../../../../scalars.ts';
import Message from '../../../technical/Message.tsx';
import { parseUtcDate } from '../../../date.utils.ts';
import SubFundBalanceChart from './SubFundBalanceChart.tsx';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import { SubFundLastDayBarChart } from './SubFundLastDayBarChart.tsx';
import { Suspense } from 'react';
import Loader from 'components/technical/Loader/Loader.tsx';

const LAYOUT_SPACING = 4;
export interface SubFundTwrRow {
  subFund: {
    id: number;
    name: string;
  };
  twr: ITimeWeightedReturnType[];
}

const gridFullWidthItemProps = {
  sm: 12,
  md: 12,
} as const;

// when given asset is provided, we show data only for it as a reference asset, otherwise we display data for usd
const AssetSection = ({
  asset,
  subFundTwr,
  subFundIdToColorIndex,
}: {
  asset?: { id: string; symbol: string };
  subFundTwr: {
    subFund: {
      id: number;
      name: string;
      referenceAsset: {
        id: string;
      };
    };
    referenceAssetReturns: { twr: ITimeWeightedReturnType[] };
    usdReturns: { twr: ITimeWeightedReturnType[] };
  }[];
  subFundIdToColorIndex: Map<number, number>;
}) => {
  const twrRows = subFundTwr
    .map((row) => {
      if (isNil(asset)) {
        return {
          subFund: row.subFund,
          twr: row.usdReturns.twr,
        };
      }

      if (row.subFund.referenceAsset.id !== asset.id) {
        return null;
      }

      return {
        subFund: row.subFund,
        twr: row.referenceAssetReturns.twr,
      };
    })
    .filter((rows) => !isNil(rows));

  const performanceTwrRows = subFundTwr
    .map((row) => {
      if (isNil(asset)) {
        // show always reference asset performance
        return {
          subFund: row.subFund,
          twr: row.referenceAssetReturns.twr,
        };
      }

      if (row.subFund.referenceAsset.id !== asset.id) {
        return null;
      }

      return {
        subFund: row.subFund,
        twr: row.referenceAssetReturns.twr,
      };
    })
    .filter((rows) => !isNil(rows));

  const twrFirstDate = twrRows
    .map((row) => {
      return row.twr.at(0)?.date;
    })
    .filter((date) => !isNil(date))
    .map((date) => parseUtcDate(date));

  const twrLastDate = twrRows
    .map((row) => {
      return row.twr.at(-1)?.date;
    })
    .filter((date) => !isNil(date))
    .map((date) => parseUtcDate(date));

  if (twrLastDate.length === 0) {
    return <Message>No twr data</Message>;
  }

  const maxTwrDate = dayjs.max(twrLastDate);

  const assetSymbol = asset?.symbol ?? 'USD';

  const firstDayLabel = dayjs(twrFirstDate[0]).format('DD-MM-YYYY');
  const lastDayLabel = dayjs(twrLastDate[0]).format('DD-MM-YYYY');
  return (
    <Grid container gap={4}>
      <Grid {...gridFullWidthItemProps}>
        <Card>
          <TwoThirdsLayout
            title="Performance [%]"
            spacing={LAYOUT_SPACING}
            withDivider
            left={
              <SubFundPerformanceLineChart
                title={`from ${firstDayLabel} to ${lastDayLabel}`}
                data={performanceTwrRows}
                twrField={'weight'}
              />
            }
            right={
              <SubFundLastDayBarChart
                data={
                  performanceTwrRows
                    .map((row) => ({
                      subFund: {
                        id: row.subFund.id,
                        name: row.subFund.name,
                      },
                      twr: [row.twr.find((twr) => parseUtcDate(twr.date).isSame(maxTwrDate))],
                    }))
                    .filter(
                      (row): row is Omit<(typeof twrRows)[number], 'twr'> & { twr: ITimeWeightedReturnType[] } =>
                        !isNil(row.twr)
                    ) ?? []
                }
                twrField={'weight'}
              />
            }
          />
        </Card>
      </Grid>
      <Grid {...gridFullWidthItemProps}>
        <Card>
          <TwoThirdsLayout
            spacing={LAYOUT_SPACING}
            title={`P&L [${assetSymbol}]`}
            withDivider
            left={
              <SubFundPerformanceLineChart
                title={`from ${firstDayLabel} to ${lastDayLabel}`}
                data={twrRows}
                twrField={'value'}
              />
            }
            right={
              <SubFundLastDayBarChart
                data={twrRows
                  .map((row) => ({
                    subFund: {
                      id: row.subFund.id,
                      name: row.subFund.name,
                    },
                    twr: [row.twr.find((twr) => parseUtcDate(twr.date).isSame(maxTwrDate))],
                  }))
                  .filter(
                    (row): row is Omit<(typeof twrRows)[number], 'twr'> & { twr: ITimeWeightedReturnType[] } =>
                      !isNil(row.twr)
                  )}
                twrField={'value'}
              />
            }
          />
        </Card>
      </Grid>
      <Grid {...gridFullWidthItemProps}>
        <Card>
          <TwoThirdsLayout
            title={`Balance [${assetSymbol}]`}
            spacing={LAYOUT_SPACING}
            withDivider
            left={
              <SubFundPerformanceLineChart
                title={`from ${firstDayLabel} to ${lastDayLabel}`}
                data={twrRows}
                twrField={'balance'}
              />
            }
            right={
              <SubFundBalanceChart
                title={`Last day: ${lastDayLabel}`}
                subFundIdToColorIndex={subFundIdToColorIndex}
                data={twrRows
                  .map((row) => ({
                    ...row,
                    twr: row.twr.find((twr) => parseUtcDate(twr.date).isSame(maxTwrDate)),
                  }))
                  .filter(
                    (row): row is Omit<(typeof twrRows)[number], 'twr'> & { twr: ITimeWeightedReturnType } =>
                      !isNil(row.twr)
                  )}
              />
            }
          />
        </Card>
      </Grid>
    </Grid>
  );
};

const SubFundPnlResult = ({ dimension, startDate }: { dimension: string | null; startDate: Dayjs | null }) => {
  const { data } = useSubFundPnlSuspenseQuery({
    variables: {
      dimensions: isNil(dimension) ? [] : [dimension],
      since: isNil(startDate) ? null : formatISODate(startDate),
    },
  });

  const subFundRows = data.portfolio.subFundsTimeWeightedReturns.subFunds;
  const idToReferenceAsset = new Map(
    subFundRows.map((row) => [row.subFund.referenceAsset.id, row.subFund.referenceAsset])
  );
  const sortedReferenceAssets = sortBy((asset) => asset.symbol.toLowerCase(), Array.from(idToReferenceAsset.values()));
  const uniqueSubFunds = uniqBy(
    (subFund) => subFund.id,
    subFundRows.map((row) => row.subFund)
  );
  const allSubFundsSorted = sortBy((subFund) => subFund.name.toLowerCase(), uniqueSubFunds);
  const subFundToColorIndex = new Map(allSubFundsSorted.map((subFund, index) => [subFund.id, index]));
  return (
    <Stack spacing={defaultRowSpacing}>
      <SubFundPnlGrid data={subFundRows} />
      <Stack>
        <Suspense fallback={<Loader />}>
          <HeaderBar title={'All sub-funds'} />
          <AssetSection subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
        </Suspense>
      </Stack>

      {sortedReferenceAssets.map((asset) => (
        <div key={asset.id}>
          <HeaderBar title={`${asset.symbol} P&L`} />
          <AssetSection asset={asset} subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
        </div>
      ))}
    </Stack>
  );
};

export default SubFundPnlResult;
