import type * as Highcharts from 'highcharts';
import {
  tooltipFormat,
  type HighchartSeries,
  getHighchartColor,
  highChartsNegativeColorIndex,
  highChartsPositiveColorIndex,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import type { SubFundTwrRow } from './SubFundPnlResult';
import { isNil } from 'lodash/fp';
import { Skeleton, Stack, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { tooltipFormatter, valueFormatter, type SupportedTwrFields } from './SubFundPerformanceLineChart';
import { Suspense } from 'react';
import { bignumber } from 'mathjs';
import { useFinalColorScheme } from 'useFinalColorScheme';

const calculateOptions = (categories: string[], field: SupportedTwrFields): Highcharts.Options => {
  return {
    chart: {
      type: 'bar',
    },
    ...tooltipFormat,
    xAxis: {
      categories,
      lineWidth: 0,
      tickInterval: Math.ceil(categories.length / 10), // Reduce steps dynamically
      min: 0,
      max: Math.min(categories.length - 1, categories.length + 1), // Auto-zoom on first n bars
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: tooltipFormatter[field],
      },
    },
    legend: {
      enabled: false,
      itemStyle: {
        color: 'inherit',
      },
    },
  };
};

const calculateChartData = ({
  colorScheme,
  data,
  categories,
  twrField,
}: {
  colorScheme: 'dark' | 'light';
  data: SubFundTwrRow[];
  categories: string[];
  twrField: SupportedTwrFields;
}): HighchartSeries[] => {
  const seriesData = categories.map((subFundName, index) => {
    const dataItem = data.find((item) => item.subFund.name === subFundName);
    const twrValue = dataItem?.twr[0]?.return[twrField];

    const colorIndex = twrValue
      ? bignumber(twrValue).isNegative()
        ? highChartsNegativeColorIndex
        : highChartsPositiveColorIndex
      : highChartsNegativeColorIndex;
    return {
      x: index, // Ensures a unique position on x-axis
      y: twrValue ? bignumber(twrValue).abs().toNumber() : 0,
      color: getHighchartColor(colorScheme, colorIndex),
      textValue: valueFormatter[twrField](twrValue),
    };
  });

  return [
    {
      name: twrField,
      data: seriesData,
      type: 'column',
    },
  ];
};

export const SubFundLastDayBarChart = ({
  data,
  twrField,
}: { data: SubFundTwrRow[] | null; twrField: SupportedTwrFields }) => {
  const colorScheme = useFinalColorScheme();

  if (isNil(data) || data.length === 0) {
    console.log('SubFundLastDayBarChart can`t render because data is null or empty');
    return null;
  }

  // Create an array of subFund names with their TWR values
  const categoriesWithValues = data.map((item) => ({
    name: item.subFund.name,
    value: bignumber(item.twr[0]?.return[twrField] ?? 0).toNumber(),
  }));

  // Sort by value descending (biggest positive first)
  const sortedCategories = categoriesWithValues.sort((a, b) => b.value - a.value).map((item) => item.name); // Extract sorted names for the x-axis

  const dateSubTitle = dayjs(data[0].twr[0].date.toString()).format('DD-MM-YYYY');

  const options = calculateOptions(sortedCategories, twrField);

  return (
    <Stack gap={2}>
      <Typography level="body-xs">Last day: {dateSubTitle}</Typography>
      <Suspense fallback={<Skeleton />}>
        <HighChartsWrapper
          exporting={false}
          loading={false}
          data={data}
          calculateChartData={(data) =>
            calculateChartData({
              colorScheme,
              data,
              categories: sortedCategories,
              twrField,
            })
          }
          calculateOptions={(): Highcharts.Options => options}
        />
      </Suspense>
    </Stack>
  );
};
