import { Card, Stack } from '@mui/joy';
import { Box } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { fallbackAssetLabels } from 'components/portfolio/PortfolioAssets';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar.tsx';
import Help from 'components/technical/Help/Help';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { usePublicAssetSpotQuery } from 'generated/graphql';

import AssetCorrelationContainer from './AssetCorrelation/AssetCorrelationContainer';
import AssetMetricsChart from './AssetMetricsChart/AssetMetricsChart';
import AssetPerformanceChart from './AssetPerformanceChart/AssetPerformanceChart.tsx';
import PublicAssetForecastChart from './PublicAssetForecastChart/PublicAssetForecastChart';
import { getPublicAssets } from '../../Asset.types';
import NewsSectionContainer from '../../NewsSectionContainer.tsx';
import EventSectionContainer from '../event/EventSectionContainer';
import { isNil } from 'lodash/fp';

const PublicAssetSpotTab: FunctionComponent = () => {
  const { assetId } = useParams();

  const { loaded, Fallback, data } = useDefaultErrorHandling(
    usePublicAssetSpotQuery({
      variables: {
        assetId: assetId!,
      },
      skip: !assetId,
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const supportedAssets = data.assets.feature;
  const asset = getPublicAssets(data.assets.list).find((asset) => asset.id === assetId);

  if (!asset) {
    return <Message>{`Invalid asset id ${assetId}`}</Message>;
  }

  const defaultAssets = supportedAssets.filter(
    (supportedAsset) =>
      !isNil(supportedAsset.label) &&
      fallbackAssetLabels.includes(supportedAsset.label) &&
      supportedAsset.label !== asset.label
  );

  return (
    <Stack spacing={1.5}>
      <TwoThirdsLayout
        left={
          <Stack spacing={1.5}>
            <SectionColumn>
              <ActionsContextProvider>
                <ActionsHeaderBar
                  title={
                    <>
                      Asset Performance <Help>Key asset&apos;s metrics and respective weekly variation.</Help>
                    </>
                  }
                />
                <AssetPerformanceChart asset={asset} />
              </ActionsContextProvider>
            </SectionColumn>
            <SectionColumn>
              <ActionsHeaderBar
                title={
                  <>
                    Price forecast{' '}
                    <Help>
                      Past price and predicted price for the next 30 days. The purple area represent the 95% confidence
                      intervals for the forecasts. Predictions are updated on an hourly basis to include the most recent
                      market dynamics.
                    </Help>
                  </>
                }
              />
              <PublicAssetForecastChart asset={asset} />
            </SectionColumn>
          </Stack>
        }
        right={
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <SectionColumn>
              <HeaderBar title="News" />
              <Card>
                <NewsSectionContainer assetIds={[asset.id]} />
              </Card>
            </SectionColumn>
          </Box>
        }
      />

      <TwoThirdsLayout
        left={
          <SectionColumn>
            <ActionsContextProvider>
              <ActionsHeaderBar title="Asset metrics" />
              <AssetMetricsChart asset={asset} metrics={data.assets.availableMetrics.map((av) => av.label)} />
            </ActionsContextProvider>
          </SectionColumn>
        }
        right={<EventSectionContainer assetId={asset.id} />}
      />

      <SectionColumn>
        <ActionsContextProvider>
          <AssetCorrelationContainer asset={asset} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
        </ActionsContextProvider>
      </SectionColumn>
    </Stack>
  );
};

export default PublicAssetSpotTab;
