import dayjs from 'dayjs';
import sortBy from 'lodash/fp/sortBy';
import type { ReactElement } from 'react';

import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { calculatePerAssetMetrics } from './HistoricalCorrelationService';
import {
  type ICorrelationsQuery,
  type ICorrelationsQueryVariables,
  useCorrelationsQuery,
} from '../../../../generated/graphql';
import { formatNumber } from '../../../formatter.utils';
import type Highcharts from 'highcharts';
import type { CorrelationAsset } from './Correlation.utils.ts';

type CalculateChartDataProps = {
  data: ICorrelationsQuery;
  filename: string;
  selectedAssets: CorrelationAsset[];
  labelProvider: (firstAsset: CorrelationAsset, secondAsset: CorrelationAsset) => string;
};

const calculateChartData = (props: CalculateChartDataProps): HighchartSeries[] => {
  const idToAsset: Record<string, CorrelationAsset> = Object.fromEntries(
    props.selectedAssets.map((asset) => [asset.id, asset])
  );

  const assetMetrics = calculatePerAssetMetrics(props.data.assets.twoAssetMetrics, idToAsset, props.labelProvider);
  const sortedAssetEntries = sortBy((row) => row.name, assetMetrics);

  return sortedAssetEntries.map((assetRow) => ({
    name: assetRow.name,
    data: sortBy(
      (row) => row.x,
      assetRow.date.map((item, i) => ({
        x: dayjs.utc(item.toString()).valueOf(),
        y: assetRow.value[i],
        textValue: formatNumber(assetRow.value[i]),
      }))
    ),
    type: 'line',
  }));
};

const calculateOptions = (filename: string): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(filename),
    ...tooltipFormat,
    ...noYAxisTitle,
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const HistoricalCorrelation = ({
  selectedAssets,
  filename,
  queryInput,
  labelProvider,
}: {
  selectedAssets: CorrelationAsset[];
  filename: string;
  queryInput: ICorrelationsQueryVariables;
  labelProvider: (firstAsset: { id: string; symbol: string }, secondAsset: { id: string; symbol: string }) => string;
}): ReactElement => {
  const queryOutput = useCorrelationsQuery({
    variables: {
      ...queryInput,
    },
  });

  return (
    <HighChartsContainer<ICorrelationsQuery>
      {...queryOutput}
      calculateOptions={(): Highcharts.Options => calculateOptions(filename)}
      calculateChartData={(data): HighchartSeries[] =>
        calculateChartData({ data, filename, selectedAssets, labelProvider })
      }
    />
  );
};

export default HistoricalCorrelation;
